/**
 * External Imports
 */
import { NeuTable } from '@neutron/react'
/**
 * Internal Imports
 * */
import { Chip } from '../reusableComponents/neutronComponents/chip/chip'
import IsolationStatusBadge from '../table/IsolationStatusBadge'
/**
 * Styling Imports
 */
import { IsolationWrap } from './reports.styles'

const ReportsList = ({ reportingData, path, columns }) => {
  let formattedData

  return (
    reportingData.length > 0 &&
    reportingData.map((data, index) => {
      switch (path) {
        case 'snapshot':
          formattedData = [
            <div>{data.unit}</div>,
            <div>{data.bed}</div>,
            <div>
              <div>{data.patientName}</div>
              <div>{data.patientMeta} </div>
              <IsolationWrap>
                <IsolationStatusBadge
                  isolationStatusList={data.isolationStatus}
                  keyProp={data.id}
                />
              </IsolationWrap>
            </div>,
            data.careTeam.map((data, index) => {
              return (
                <Chip
                  key={index}
                  roleText={data.slotAbbreviation}
                  assignmentText={data.teamMember}
                  isClickDisabled={true}
                  action={''}
                  staged={false}
                  assignedUser={true}
                  selected={false}
                  context={'slidingPane'}
                />
              )
            }),
            <div>{data.lastUpdated}</div>,
          ]

          break
        case 'missingassignments':
          formattedData = [
            <div style={{ paddingLeft: '0px' }}>{data.unit}</div>,
            <div style={{ paddingLeft: '0px' }}>{data.bed}</div>,
            <div>
              <div>{data.patientName}</div>
              <div>{data.patientMeta} </div>
              <IsolationWrap>
                <IsolationStatusBadge
                  isolationStatusList={data.isolationStatus}
                  keyProp={data.id}
                />
              </IsolationWrap>
            </div>,
            <div style={{ paddingLeft: '0px' }}>{data.missingSlot}</div>,
            <div style={{ paddingLeft: '0px' }}>{data.missingSince}</div>,
            <div
              style={{
                paddingLeft: '0px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ paddingLeft: '40px' }}>
                {data.wasCompletedByDischarged ? 'Discharged' : ''}
              </div>
              {!data.wasCompletedByDischarged && <div>{data.modifiedOn}</div>}
            </div>,
          ]
          break
        case 'assignmentaudit':
          formattedData = [
            <div>{data.time}</div>,
            <div style={{ paddingLeft: '0px' }}>{data.unit}</div>,
            <div style={{ paddingLeft: '0px' }}>{data.bed}</div>,
            <div>
              <div>{data.patientName}</div>
              <div>{data.patientMeta} </div>
            </div>,
            <div style={{ paddingLeft: '0px' }}>{data.action}</div>,
            <div style={{ paddingLeft: '0px' }}>{data.position}</div>,
            <div style={{ paddingLeft: '0px' }}>
              <div>{data.staffMember}</div>
              <div>{data.assignment34}</div>
            </div>,
            <div style={{ paddingLeft: '0px' }}>
              <div>{data.madeBy}</div>
              <div>{data.user34}</div>
            </div>,
          ]
          break
        default:
          break
      }
      return (
        <NeuTable
          unit="row"
          key={index}
          style={{ width: '100%', display: 'flex' }}
        >
          {formattedData.map((val, index) => {
            return (
              <NeuTable
                unit="column"
                key={index}
                style={{
                  fontSize: '14px',
                  width: '100%',
                  display: 'flex',
                  flex:
                    path === 'snapshot' && index === 3
                      ? '0 0 720px'
                      : undefined,
                }}
              >
                {val}
              </NeuTable>
            )
          })}
        </NeuTable>
      )
    })
  )
}

export default ReportsList
