import { NeuIcon, NeuButton } from '@neutron/react'
import { FlatTabButton } from '../../styleLibrary'
import { publishFunction } from '../../../../utils/publish'

//styles
import { AssignToAllModal } from './assigning.styles'

const AssigningToAllUnits = ({
  showAssignToAllUnits,
  dispatch,
  hideAssignToAllModal,
  handlePublishState,
  stagedStaffSlots,
  page,
  siteId,
  selectedUnitIds,
  hideModal,
  authToken,
  ui,
  allUnitsSelected,
}) => {
  const showAssignToAllClass = showAssignToAllUnits
    ? 'modal display-block'
    : 'modal display-none'

  return (
    <div className={showAssignToAllClass}>
      <AssignToAllModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <h6
            style={{
              fontFamily: 'HCA-Mark',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            Assigning To All Units
          </h6>
          <FlatTabButton
            onClick={() => {
              dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })

              hideAssignToAllModal()
            }}
          >
            <NeuIcon
              large
              style={{
                color: '#898B8E',
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
            lineHeight: '10px',
          }}
        >
          <p className="neu-text">
            This will now replace all current assignments, please confirm.
          </p>
        </div>
        <div
          className={'navItemText'}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            marginTop: '20px',
          }}
        >
          <NeuButton
            fill="flat-white"
            className="neu-button"
            style={{ color: '#CE2130', fontFamily: 'HCA-Mark' }}
            onClick={() => {
              hideAssignToAllModal()
            }}
          >
            Cancel
          </NeuButton>

          <NeuButton
            className="neu-button"
            style={{ backgroundColor: '#00558C', color: 'white' }}
            onClick={() => {
              handlePublishState()

              publishFunction(
                stagedStaffSlots,
                page,
                siteId,
                selectedUnitIds,
                hideModal,
                authToken,
                ui,
                allUnitsSelected,
              )
              dispatch({
                type: 'ui/TABLE_CHECKBOX_CHECKED',
                payload: false,
              })
              hideAssignToAllModal()
            }}
          >
            Publish
          </NeuButton>
        </div>
      </AssignToAllModal>
    </div>
  )
}

export default AssigningToAllUnits
