/**
 * External Imports
 */
import styled from 'styled-components'

const Flex = styled.div`
    display: flex;
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')}
    flex-direction: ${(props) => props.direction};
`
export const FlatTabButton = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
`
export const UserChip = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const HoverDiv = styled.div`
  position: relative;

  &::after {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    content: ${(props) => `'${props.fullName}'`} !important;
    background-color: black !important;
    color: white !important;
    padding: 3px !important;
    border-radius: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 1.2s;
    pointer-events: none; /* Prevent the tooltip from interfering with hover */
  }

  &:hover::after {
    opacity: 1;
  }
`
export default Flex
