/**
 * External Imports
 * */
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { NeuAvatar, NeuIcon } from '@neutron/react'
import { Chip } from './neutronComponents/chip/chip'
/**
 * Styling Imports
 * */
import Flex, { HoverDiv } from './styleLibrary'
import { FlatTabButton, UserChip } from './styleLibrary'
import { SlidingPaneDiv } from './locationsSlidingPane/locationsSlidingPane.styles'

const PatientSlidingPane = ({
  slidingPane,
  showHideClassName,
  hideSlidingPane,
  rowData,
}) => {
  const baseHeader = {
    locationName: '',
    patientName: '',
    patientDOB: '',
    patientGender: '',
    patientMRN: '',
  }
  const assignmentSlidingPaneOpen = useSelector(
    (state) => state.ui.assignmentSlidingPane.open,
  )
  const modalSlide = assignmentSlidingPaneOpen ? 'slideLeft' : 'slideRight'
  const header = Object.assign(
    {},
    baseHeader,
    slidingPane.data && slidingPane.data.headerData,
  )

  return (
    <div className={showHideClassName}>
      <div
        onClick={hideSlidingPane}
        style={{ height: '100%', width: '100%', display: 'inline-block' }}
      />
      <SlidingPaneDiv
        className={modalSlide}
        style={{ overflowY: 'scroll', display: 'inline-block' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
            marginBottom: '15px',
          }}
        >
          <div style={{ flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '12px',
              }}
            >
              <h6 style={{ fontWeight: 'bold', fontFamily: 'HCA-Mark' }}>
                {header.locationName}
              </h6>
              <h6
                style={{
                  paddingLeft: '10px',
                  fontFamily: 'HCA-Mark',
                  color: '#1F2532',
                }}
              >
                {header.patientName}
              </h6>
            </div>

            <p
              style={{
                fontFamily: 'HCA-Mark',
                color: '#58595B',
                fontSize: '16px',
              }}
            >
              {header.patientGender && header.patientDOB && header.patientMRN
                ? `${header.patientGender} | ${header.patientDOB} | ${header.patientMRN}`
                : ' '}
            </p>
          </div>

          <FlatTabButton onClick={hideSlidingPane}>
            <NeuIcon
              style={{
                fontSize: '24px',
                color: 'grey',
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {slidingPane.data &&
            slidingPane.data.data &&
            slidingPane.data.data.map((group, index) => {
              return (
                <div
                  key={group.label + index}
                  className={rowData.length - 1 === index ? 'bb' : ''}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 20px',
                    borderTop: '2px solid #DDD',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        width: '190px',
                        paddingTop: '1em',
                      }}
                    >
                      <label
                        style={{
                          fontFamily: 'HCA-Mark',
                          color: '#58595B',
                          fontWeight: '500',
                        }}
                      >
                        {group.label}
                      </label>
                    </div>
                    <div
                      style={{
                        width: '190px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {group.data.map((slot) => {
                        return (
                          <React.Fragment key={slot.key + slot}>
                            <Flex
                              direction="column"
                              style={{
                                alignSelf: 'flex-end',
                                marginTop: '5px',
                                marginBottom: '5px',
                              }}
                            >
                              <HoverDiv fullName={slot.fullName}>
                                <Chip
                                  roleText={slot.abbreviation}
                                  assignmentText={slot.userAssigned}
                                  isClickDisabled={true}
                                  action={''}
                                  staged={false}
                                  assignedUser={true}
                                  selected={false}
                                  context={'slidingPane'}
                                  avatar="true"
                                  removable="false"
                                  onNeuChange="myFunction"
                                  style={{
                                    pointerEvents: 'none',
                                    width: '140px',
                                    height: '32px',
                                    fontSize: '14px',
                                    letterSpacing: '0.2px',
                                    color: '#54575a',
                                    backgroundColor: '#bcbcbc',
                                  }}
                                >
                                  <UserChip>{slot.userAssigned}</UserChip>
                                  <NeuAvatar
                                    slot="start"
                                    style={{
                                      width: 'max-content',
                                      height: '26px',
                                      borderRadius: '15px',
                                      backgroundColor: '#54575a',
                                      color: 'white',
                                      padding: '9px',
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {slot.abbreviation}
                                  </NeuAvatar>
                                </Chip>
                              </HoverDiv>
                            </Flex>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </SlidingPaneDiv>
    </div>
  )
}
const mapReduxStateToProps = function (state) {
  return {
    rowData: state.rowData.rowData,
  }
}

export default connect(mapReduxStateToProps)(PatientSlidingPane)
