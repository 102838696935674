/**
 * External Imports
 * */
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { NeuDatepicker, NeuIcon } from '@neutron/react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { Dropdown } from 'semantic-ui-react'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

/**
 * Internal Imports
 * */
import { MultiSelectDropdown } from '../MultiselectDropdown'
import Events from '../../../analytics/events'

import {
  getAssignmentAudit,
  getSnapshot,
  getMissingAssignments,
} from '../../../redux/actions/reports'
/**
 * Styling Imports
 * */
import Flex from '../styleLibrary'
import Input from '../neutronComponents/input/input'
import {
  SlidingPaneDiv,
  CancelButton,
  RunButton,
} from './modifyReportSlidingPane.styles'

const ModifyReport = ({
  units,
  positions,
  user34,
  site,
  dispatch,
  authToken,
  hideSlidingPane,
  showHideReportClassName,
  path: propsPath,
  previousPath,
  prefersReducedMotion,
}) => {
  const [showPane, setShowPane] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowPane(true)
    }, 100)
  }, [])

  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)
  const reportsSlidingPaneOpen = useSelector(
    (state) => state.ui.reportSlidingPane.open,
  )

  const paneClassName = `reportSlidingPane ${reportsSlidingPaneOpen ? 'reportSlideRight inner-shadow outer-shadow' : 'reportSlideLeft'} ${showPane ? 'show' : 'hide'}`

  const path = window.location.pathname.replace('/reports/', '')

  const prevSiteRef = useRef(site)

  const currentPath = window.location.pathname

  // Dropdown Options
  const typeOptions = [
    { key: 'All', value: 'All', text: 'All' },
    { key: 'Location', value: 'Location', text: 'Location' },
    { key: 'Patient', value: 'Patient', text: 'Patient' },
  ]

  const unitOptions = [{ key: 'All', value: 'All', text: 'All' }]
  units.forEach((unit, index) => {
    unitOptions.push({ key: index, value: unit.id, text: unit.displayName })
  })

  const allPosition = [{ key: 'All', value: 'All', text: 'All' }]
  const positionOptions = allPosition.concat(positions)

  const timeOptions = [
    { key: '4', value: '4', text: 'Last 4 Hours' },
    { key: '8', value: '8', text: 'Last 8 Hours' },
    { key: '12', value: '12', text: 'Last 12 Hours' },
    { key: '24', value: '24', text: 'Last 24 Hours' },
    { key: '7Days', value: '7Days', text: 'Last 7 Days' },
  ]

  const getLocalData = JSON.parse(
    localStorage.getItem(`${user34}-${path}-${site}`),
  )
  // Initial Dropdown States
  let localType
  if (getLocalData !== null && getLocalData.type) {
    localType = getLocalData.type
  } else {
    localType = 'All'
  }

  let localUnits
  if (getLocalData !== null && getLocalData.unit) {
    localUnits = getLocalData.unit
  } else {
    localUnits = [{ key: 'All', value: 'All', text: 'All' }]
  }

  let localPositions
  if (getLocalData !== null && getLocalData.position) {
    localPositions = getLocalData.position
  } else {
    localPositions = [{ key: 'All', value: 'All', text: 'All' }]
  }

  let localDate
  if (getLocalData !== null && getLocalData.date && path === 'snapshot') {
    localDate = getLocalData.date
  } else {
    localDate = moment().subtract(1, 'day').format('MM-DD-YYYY')
  }

  let localTime
  if (getLocalData !== null && getLocalData.time) {
    localTime = getLocalData.time
  } else {
    localTime = '12'
  }

  const localHour = getLocalData?.hour ?? '6'
  const localMinutes = getLocalData?.minute ?? '00'

  let localKeyword
  if (getLocalData !== null && getLocalData.keyword) {
    localKeyword = getLocalData.keyword
  } else {
    localKeyword = ''
  }

  const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight - 127)
  const [type, setType] = useState(localType)
  const [unit, setUnit] = useState(localUnits)
  const [position, setPosition] = useState(localPositions)
  const [positionList, setPositionList] = useState(positionOptions)
  const [date, setDate] = useState(localDate)
  const [time, setTime] = useState(localTime)
  const [hour, setHour] = useState(localHour)
  const [minute, setMinute] = useState(localMinutes)
  const [keyword, setKeyword] = useState(localKeyword)

  const defaultUnit = useMemo(
    () => [{ key: 'All', value: 'All', text: 'All' }],
    [],
  )
  const defaultPosition = useMemo(
    () => [{ key: 'All', value: 'All', text: 'All' }],
    [],
  )

  const handleResize = useCallback(() => {
    setDynamicHeight(window.innerHeight - 127)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setPositionList(positionList)

    if (type.length === 0) {
      setType('All')
    }

    if (unit.length === 0) {
      setUnit(defaultUnit)
    }

    if (position.length === 0) {
      setPosition(defaultPosition)
    }

    if (time.length === 0) {
      setTime('12')
    }

    if (keyword.length === 0) {
      setKeyword('')
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [
    handleResize,
    positionList,
    position,
    unit,
    defaultPosition,
    defaultUnit,
    type,
    time,
    keyword,
  ])

  useEffect(() => {
    if (
      prevSiteRef.current !== site ||
      (previousPath !== undefined && previousPath !== currentPath)
    ) {
      const storedData =
        JSON.parse(localStorage.getItem(`${user34}-${path}-${site}`)) || {}
      delete storedData.type
      delete storedData.unit
      delete storedData.position
      delete storedData.date
      delete storedData.time
      delete storedData.hour
      delete storedData.minute
      delete storedData.keyword

      localStorage.setItem(
        `${user34}-${path}-${site}`,
        JSON.stringify(storedData),
      )

      setType('All')
      setUnit(defaultUnit)
      setPosition(defaultPosition)
      setDate(moment().subtract(1, 'day').format('YYYY-MM-DD'))
      setTime('12')
      setHour('6')
      setMinute('00')
      setKeyword('')
    }
    prevSiteRef.current = site
  }, [site, currentPath, path, previousPath, defaultPosition, defaultUnit])

  const handleRunReport = () => {
    const formatType = type === 'All' ? '' : type
    const formatUnit = unit.some((u) => u.value === 'All')
      ? []
      : unit.map((u) => u.value)
    const formatPosition = position.some((pos) => pos.text === 'All')
      ? []
      : position.map((pos) => pos.text)

    const startDate =
      time === '7Days' ? moment().subtract(7, 'day').format('YYYY-MM-DD') : null
    const endDate = time === '7Days' ? moment().format('YYYY-MM-DD') : null
    const startAndEndTime = time === '7Days' ? moment().format('hh:mm A') : '0'
    const startDT = new Date().toUTCString()
    const utcTime =
      time === '7Days' ? moment.utc(startDT).format('hh:mm A') : null

    const formatTimeIncrementInHours = time === '7Days' ? '0' : time
    switch (path) {
      case 'assignmentaudit':
        const auditPositions = position.some((pos) => pos.text === 'All')
          ? []
          : position.map((pos) => pos.value)

        dispatch(
          getAssignmentAudit({
            siteId: site,
            time: time === '7Days' ? '0' : time,
            type: formatType,
            unitId: formatUnit,
            position: auditPositions,
            authToken: authToken,
            utcStartDate: startDate,
            utcEndDate: endDate,
            utcStartTime: utcTime,
            utcEndTime: utcTime,
          }),
        )
        dispatch({ type: 'ui/SET_REPORT_KEYWORD', payload: keyword })

        dispatch({
          type: 'ui/SET_AUDIT_TYPE_AND_TIME',
          payload: {
            type: type,
            time: time,
            startDate: moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            endDate: moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            startTime: startAndEndTime,
            endTime: startAndEndTime,
          },
        })
        localStorage.setItem(
          `${user34}-${path}-${site}`,
          JSON.stringify({
            type,
            unit,
            position,
            time,
            keyword,
          }),
        )
        hideSlidingPane()

        break
      case 'snapshot':
        const formattedDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        const formattedTime = `${hour}:${minute}:00`
        const newFormattedDateAndTime = new Date(
          formattedDate + ' ' + formattedTime,
        ).toUTCString()

        const snapshotUtcTime = newFormattedDateAndTime.slice(17, 22)
        const dateFromUTC = newFormattedDateAndTime.slice(5, 16)
        const newUTCDate = new Date(dateFromUTC).toLocaleDateString('en-US')
        const dateArray = newUTCDate.split('/')
        const month =
          dateArray[0].length === 1 ? '0' + dateArray[0] : dateArray[0]
        const day =
          dateArray[1].length === 1 ? '0' + dateArray[1] : dateArray[1]
        const year = dateArray[2]
        const snapshotUtcDate = year + '-' + month + '-' + day
        dispatch({ type: 'ui/SET_REPORT_KEYWORD', payload: keyword })
        dispatch(
          getSnapshot({
            date: snapshotUtcDate,
            time: snapshotUtcTime,
            //position will always be [] for the call we will filter once we get data
            position: [],
            unit: formatUnit,
            authToken: authToken,
            siteId: site,
          }),
        )
        dispatch({
          type: 'ui/SET_SNAPSHOT_DATE_AND_TIME',
          payload: {
            date: moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            time: `${hour}:${minute}`,
            position: formatPosition,
          },
        })
        localStorage.setItem(
          `${user34}-${path}-${site}`,
          JSON.stringify({
            unit,
            position,
            date,
            hour,
            minute,
            keyword,
          }),
        )
        hideSlidingPane()

        break
      case 'missingassignments':
        const startDateTime =
          moment(startDate).format('MM/DD/YYYY') + ' ' + startAndEndTime
        const endDateTime =
          moment(endDate).format('MM/DD/YYYY') + ' ' + startAndEndTime
        const timeStamp = moment({}).format('MM/DD/YYYY, HH:mm')
        const assignmentCall = () => {
          dispatch({
            type: 'ui/SET_MISSING_ASSIGNMENT_DATA',
            payload: {
              timeIncrementInHours: formatTimeIncrementInHours,
              timeReportGenerated: timeStamp,
              startDateTime,
              endDateTime,
            },
          })
          dispatch(
            getMissingAssignments({
              timeIncrementInHours: formatTimeIncrementInHours,
              startDate,
              startTime: utcTime,
              endDate,
              endTime: utcTime,
              position: formatPosition,
              unit: formatUnit,
              authToken: authToken,
              siteId: site,
            }),
          )
          localStorage.setItem(
            `${user34}-${path}-${site}`,
            JSON.stringify({
              unit,
              position,
              time,
            }),
          )
          hideSlidingPane()
        }
        assignmentCall()
        break
      default:
        break
    }
  }

  // Create an array of the last 7 dates in 'YYYY-MM-DD' format
  const arrayOf7Dates = Array.from({ length: 7 }, (_, i) =>
    moment().subtract(i, 'days').format('YYYY-MM-DD'),
  )

  // Create an array of disabled dates, including 60 days before and after today, excluding the last 7 dates
  const disabledDates = Array.from({ length: 60 }, (_, i) =>
    moment().subtract(i, 'days').format('YYYY-MM-DD'),
  )
    .concat(
      Array.from({ length: 60 }, (_, i) =>
        moment()
          .add(i + 1, 'days')
          .format('YYYY-MM-DD'),
      ),
    )
    .filter((date) => !arrayOf7Dates.includes(date))

  // Set the minimum date to the start of the previous month
  const minDate = moment()
    .subtract(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD')

  // Set the maximum date to the end of the current month
  const maxDate = moment().endOf('month').format('YYYY-MM-DD')

  // Parse the selected date
  const parsedDate = new Date(date)

  // Extract the year, month, and day from the parsed date
  const year = parsedDate.getFullYear()
  const month = parsedDate.getMonth() + 1
  const day = parsedDate.getDate()

  const { logTrackingEvent } = useAnalyticsApi()
  const ctaLastPath = localStorage.getItem('ctaLastPath')

  // Tracking Event Clicks
  const trackKeywordClick = () => {
    let eventName
    let screenName
    if (ctaLastPath.includes('reports/snapshot')) {
      eventName = Events.Reports.Screen[0].Events.KeywordClick
      screenName = Events.Reports.Screen[0].Name
    } else if (ctaLastPath.includes('reports/assignmentaudit')) {
      eventName = Events.Reports.Screen[1].Events.KeywordClick
      screenName = Events.Reports.Screen[1].Name
    }
    const event = {
      module: Events.Reports.Module,
      screen: screenName,
      eventName: eventName,

      eventType: 'action',
      org: {
        orgId: orgId,
        orgLevel: 'Facility',
      },
    }

    logTrackingEvent([event])
  }

  const trackCancelClick = () => {
    let cancelEventName
    let cancelScreen
    if (ctaLastPath.includes('reports/snapshot')) {
      cancelEventName = Events.Reports.Screen[0].Events.Cancel_ButtonClick
      cancelScreen = Events.Reports.Screen[0].Name
    } else if (ctaLastPath.includes('reports/missingassignments')) {
      cancelEventName = Events.Reports.Screen[2].Events.Cancel_ButtonClick
      cancelScreen = Events.Reports.Screen[2].Name
    } else if (ctaLastPath.includes('reports/assignmentaudit')) {
      cancelEventName = Events.Reports.Screen[1].Events.Cancel_ButtonClick
      cancelScreen = Events.Reports.Screen[1].Name
    }

    const event = {
      module: Events.Reports.Module,
      screen: cancelScreen,
      eventName: cancelEventName,
      eventType: 'action',
      org: {
        orgId: orgId,
        orgLevel: 'Facility',
      },
    }

    logTrackingEvent([event])
  }

  const trackRunReportClick = () => {
    let eventName
    let screen

    if (ctaLastPath.includes('reports/snapshot')) {
      eventName = Events.Reports.Screen[0].Events.Run_ReportClick
      screen = 0
    } else if (ctaLastPath.includes('reports/missingassignments')) {
      eventName = Events.Reports.Screen[2].Events.Run_ReportClick
      screen = 2
    } else if (ctaLastPath.includes('reports/assignmentaudit')) {
      eventName = Events.Reports.Screen[1].Events.Run_ReportClick
      screen = 1
    }
    const event = {
      module: Events.Reports.Module,
      screen: Events.Reports.Screen[screen].Name,
      eventName: eventName,
      eventType: 'action',
      org: {
        orgId: orgId,
        orgLevel: 'Facility',
      },
    }

    logTrackingEvent([event])
  }

  return (
    <div className={showHideReportClassName}>
      <SlidingPaneDiv
        className={paneClassName}
        prefersReducedMotion={prefersReducedMotion}
        style={{ height: dynamicHeight, marginTop: '4px' }}
      >
        <Flex
          direction={'column'}
          className="inner-shadow"
          style={{
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            justifyContent: 'space-between',
            width: '430px',
          }}
        >
          <Flex direction={'column'} style={{ padding: '10px 15px' }}>
            {path === 'assignmentaudit' ? (
              <Flex
                direction={'column'}
                style={{
                  marginBottom: '10px',
                }}
              >
                <label className="genericLabel">Type</label>
                <Flex style={{ width: '100%' }}>
                  <Dropdown
                    style={{ width: '390px', height: '42px' }}
                    className="genericDropdown reportSlidingPaneDropdown"
                    ui="true"
                    value={type}
                    floating
                    search
                    selection
                    options={typeOptions}
                    onChange={(e, d) => {
                      setType(d.value)
                    }}
                  />
                </Flex>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            <label className="genericLabel">Unit</label>
            <MultiSelectDropdown
              selections={unit}
              options={unitOptions}
              updateDropdownList={setUnit}
              id="unit"
              orgId={orgId}
            />
            <label className="genericLabel">Position</label>
            <MultiSelectDropdown
              selections={position}
              options={positionOptions}
              updateDropdownList={setPosition}
              id="position"
              style={{ height: '42px' }}
            />
            {propsPath === 'missingassignments' ||
            propsPath === 'assignmentaudit' ? (
              <Flex direction={'column'}>
                <label className="genericLabel">Time</label>

                <Dropdown
                  style={{ height: '42px' }}
                  className="genericDropdown reportSlidingPaneDropdown"
                  ui="true"
                  value={time}
                  search
                  floating
                  selection
                  options={timeOptions}
                  onChange={(e, d) => {
                    setTime(d.value)
                    if (propsPath === 'missingassignments') {
                      logTrackingEvent([
                        {
                          module: Events.Reports.Module,
                          screen: Events.Reports.Screen[2].Name,
                          eventName: Events.Reports.Screen[2].Events.TimeClick,
                          eventType: 'action',
                          org: {
                            orgId: orgId,
                            orgLevel: 'Facility',
                          },
                        },
                      ])
                    } else if (propsPath === 'assignmentaudit') {
                      logTrackingEvent([
                        {
                          module: Events.Reports.Module,
                          screen: Events.Reports.Screen[1].Name,
                          eventName: Events.Reports.Screen[1].Events.TimeClick,
                          eventType: 'action',
                          org: {
                            orgId: orgId,
                            orgLevel: 'Facility',
                          },
                        },
                      ])
                    }
                  }}
                />
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            {propsPath === 'snapshot' ? (
              <Flex direction={'column'}>
                <label className="genericLabel mb-2">{'Date & Time'}</label>
                <Flex>
                  <NeuDatepicker
                    kind="single"
                    calendar={true}
                    month={month}
                    day={day}
                    year={year}
                    disableDates={disabledDates}
                    minDate={minDate}
                    maxDate={maxDate}
                    style={{
                      marginRight: 10,
                      width: '230px',
                      height: 40,
                    }}
                    onNeuChange={(ev) => {
                      setDate(ev.detail.value.start)
                      logTrackingEvent([
                        {
                          module: Events.Reports.Module,
                          screen: Events.Reports.Screen[0].Name,
                          eventName:
                            Events.Reports.Screen[0].Events.Date_FieldClick,
                          eventType: 'action',
                          org: {
                            orgId: orgId,
                            orgLevel: 'Facility',
                          },
                        },
                      ])
                    }}
                  ></NeuDatepicker>
                  {propsPath === 'snapshot' && (
                    <Flex
                      style={{
                        width: '130px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Input
                        type="hour"
                        style={{
                          fontSize: '14px',
                          width: '50%',
                          border: 'solid 1px #DFDFDF',
                          borderRadius: '3px',
                          width: '59px',
                          height: '42px',
                          padding: '5px',
                        }}
                        handleInput={(e) => {
                          setHour(e)
                          logTrackingEvent([
                            {
                              module: Events.Reports.Module,
                              screen: Events.Reports.Screen[0].Name,
                              eventName:
                                Events.Reports.Screen[0].Events.Time_HourClick,
                              eventType: 'action',
                              org: {
                                orgId: orgId,
                                orgLevel: 'Facility',
                              },
                            },
                          ])
                        }}
                        value={hour}
                      />
                      <h4
                        style={{
                          marginRight: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        :
                      </h4>
                      <Input
                        type="minute"
                        style={{
                          fontSize: '14px',
                          width: '59px',
                          height: '42px',
                          border: 'solid 1px #DFDFDF',
                          borderRadius: '3px',
                          padding: '5px',
                        }}
                        handleInput={(e) => {
                          setMinute(e)
                          logTrackingEvent([
                            {
                              module: Events.Reports.Module,
                              screen: Events.Reports.Screen[0].Name,
                              eventName:
                                Events.Reports.Screen[0].Events
                                  .Time_MinuteClick,
                              eventType: 'action',
                              org: {
                                orgId: orgId,
                                orgLevel: 'Facility',
                              },
                            },
                          ])
                        }}
                        value={minute}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
            {propsPath === 'snapshot' || propsPath === 'assignmentaudit' ? (
              <Flex direction={'column'}>
                {' '}
                <label
                  style={{
                    paddingTop: '20px',
                    color: '#1F2532',
                    fontFamily: 'HCA-Mark, Arial, sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {propsPath !== 'snapshot'
                    ? 'Keyword (Name, MRN, 3-4 ID)'
                    : 'Keyword (Name, MRN)'}
                </label>
                <div className="ui left icon input Patient-Search w-100 mt-2">
                  <input
                    style={{
                      width: 330,
                      height: '42px',
                      paddingLeft: '40px',
                    }}
                    placeholder="  "
                    onChange={(e) => {
                      setKeyword(e.target.value)
                    }}
                    onClick={trackKeywordClick}
                  />
                  <NeuIcon
                    color="gray"
                    fill={true}
                    weight={300}
                    size="1x"
                    style={{
                      marginLeft: 1,
                      position: 'absolute',
                      left: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    search
                  </NeuIcon>
                </div>
              </Flex>
            ) : (
              <Flex direction={'column'}></Flex>
            )}
          </Flex>

          <Flex
            style={{
              borderTop: '2px solid #bcbcbc',
              width: '100%',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            <Flex
              style={{
                padding: '15px',
                marginTop: '10px',
                justifyContent: 'space-around',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <div>
                <CancelButton
                  kind="tertiary-plain"
                  color="primary-80"
                  size="default"
                  onClick={() => {
                    hideSlidingPane()
                    trackCancelClick()
                  }}
                >
                  Cancel
                </CancelButton>
              </div>
              <div>
                <RunButton
                  kind="primary"
                  size="default"
                  onClick={() => {
                    handleRunReport()
                    trackRunReportClick()
                  }}
                >
                  Run Report
                </RunButton>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </SlidingPaneDiv>
    </div>
  )
}

export default connect()(ModifyReport)
