/**
 * External Imports
 * */
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import Events from '../../analytics/events'
/**
 * Internal Imports
 * */
import Checkbox from './neutronComponents/checkbox/checkbox.js'

export const UnitDropdown = ({
  saveInput,
  searching,
  selectedUnits,
  units,
  isDraft,
  selectItem,
  onClose,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [localSearchText, setLocalSearchText] = useState('')
  const [filteredUnits, setFilteredUnits] = useState([...units])
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false)
        setLocalSearchText('')
        setFilteredUnits([...units])
        if (onClose) {
          onClose()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    if (isDropdownVisible) {
      selectUnitTracking()
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => {
      const newState = !prev

      if (newState) {
        setFilteredUnits([...units])
      } else {
        setFilteredUnits([...units])
        setLocalSearchText('')
        if (onClose) onClose()
      }

      return newState
    })
  }

  const handleInputChange = (event) => {
    const sanitizedSearchText = event.target.value.trim()
    setLocalSearchText(sanitizedSearchText)

    if (sanitizedSearchText) {
      const filtered = [...units].filter((unit) =>
        unit.text.toLowerCase().includes(sanitizedSearchText.toLowerCase()),
      )
      setFilteredUnits(filtered)
    } else {
      setFilteredUnits([...units])
    }

    saveInput(sanitizedSearchText)
  }

  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const selectUnitTracking = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')

    if (isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.Unit_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.Unit_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.Unit_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  return (
    <div
      id="unit-dropdown"
      ui="true"
      ref={dropdownRef}
      role="combobox"
      aria-disabled="false"
      className={`ui floating search selection dropdown ${
        isDropdownVisible ? 'active visible' : ''
      }`}
      style={{ width: '300px' }}
      onClick={(e) => {
        e.stopPropagation()
        toggleDropdown()
      }}
    >
      <input
        id="unit-input"
        className="search"
        tabIndex="0"
        type="text"
        autoComplete="off"
        style={{
          opacity: '0',
          maxWidth: '224px',
          zIndex: '99',
        }}
        value={localSearchText}
        onChange={handleInputChange}
      />
      <div
        className="text"
        style={{ maxWidth: '210px' }}
        role="alert"
        aria-live="polite"
        aria-atomic="true"
      >
        {searching && localSearchText
          ? localSearchText
          : selectedUnits.length > 1
            ? `${selectedUnits.length} Units Selected`
            : selectedUnits[0] && selectedUnits[0].text}
      </div>
      <i
        id="unit-icon"
        className="dropdown icon"
        onClick={(e) => {
          e.stopPropagation()
          toggleDropdown()
        }}
        aria-hidden="true"
      ></i>
      <div
        id="unit-dropdown-items"
        role="listbox"
        className={`menu transition ${isDropdownVisible ? 'visible' : ''}`}
      >
        {filteredUnits && isDraft
          ? filteredUnits
              .filter((unit) => unit.key !== 'all')
              .map((unit) => (
                <div
                  className="item"
                  onClick={() => selectItem(unit)}
                  key={unit.value}
                >
                  <Checkbox
                    id="unit-item-checkbox"
                    key={unit.value}
                    isSelected={
                      selectedUnits.filter((i) => i.value === unit.value)
                        .length > 0
                    }
                    click={(e) => {
                      e.stopPropagation()
                      selectItem(unit)
                    }}
                  />
                  <span
                    id={'unit-dropdown-item'}
                    style={{ paddingLeft: '10px' }}
                  >
                    {unit.text}
                  </span>
                </div>
              ))
          : filteredUnits.map((unit) => (
              <div
                className="item"
                onClick={() => selectItem(unit)}
                key={unit.value}
              >
                <Checkbox
                  id="unit-item-checkbox"
                  key={unit.value}
                  isSelected={
                    selectedUnits.filter((i) => i.value === unit.value).length >
                    0
                  }
                  click={(e) => {
                    e.stopPropagation()
                    selectItem(unit)
                  }}
                />
                <span id={'unit-dropdown-item'} style={{ paddingLeft: '10px' }}>
                  {unit.text}
                </span>
              </div>
            ))}
      </div>
    </div>
  )
}

export const DeptDropdown = ({
  saveInput,
  searching,
  selectedDept,
  options,
  selectDept,
  isDraft,
  onClose,
}) => {
  const inputRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [localSearchText, setLocalSearchText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([...options])
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const selectDeptTracking = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')
    if (isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.Dept_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (!isDraft && ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.Dept_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.Dept_Dropdown_MenuClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => {
      const newState = !prev
      if (!newState) {
        onClose()
      }
      setFilteredOptions([...options])
      setLocalSearchText('')
      return newState
    })
  }

  const handleInputChange = (event) => {
    const sanitizedSearchText = event.target.value.trim()
    setLocalSearchText(sanitizedSearchText)
    if (sanitizedSearchText) {
      const filtered = options.filter((option) =>
        option.text.toLowerCase().includes(sanitizedSearchText.toLowerCase()),
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions([...options])
    }
    saveInput(sanitizedSearchText)
  }

  const handleSelectDept = (option) => {
    selectDept(option)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setLocalSearchText('')
    setFilteredOptions([...options])
    setIsDropdownVisible(false)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false) 
        setLocalSearchText('') 
        setFilteredOptions([...options]) 
        if (onClose) onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    if (isDropdownVisible) {
      selectDeptTracking()
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose, options])

  return (
    <div
      id="dept-dropdown"
      ref={dropdownRef}
      className={`ui floating search selection dropdown ${
        isDropdownVisible ? 'active visible' : ''
      }`}
      style={{ width: '300px' }}
      onClick={(e) => {
        e.stopPropagation()
        toggleDropdown()
      }}
    >
      <input
        id="dept-input"
        ref={inputRef}
        className="search"
        tabIndex="0"
        type="text"
        autoComplete="off"
        style={{ opacity: '0', fontFamily: 'HCA-Mark', zIndex: '99' }}
        value={localSearchText}
        onChange={handleInputChange}
      />
      <div className="text" role="alert">
        {searching ? localSearchText : selectedDept.text}
      </div>
      <i
        id="dept-icon"
        className="dropdown icon"
        onClick={(e) => {
          e.stopPropagation()
          toggleDropdown()
        }}
        aria-hidden="true"
      />
      {isDropdownVisible && (
        <div
          id="dept-dropdown-items"
          role="listbox"
          className="menu transition visible"
        >
          {filteredOptions.map((option) => (
            <div
              className="item neu-text-body fw-bold"
              style={{
                background:
                  selectedDept.value === option.value
                    ? 'rgba(0,0,0,.05)'
                    : 'white',
                overflow: 'wrap',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleSelectDept(option)
              }}
              key={option.value}
              href="#"
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
