import moment from 'moment'
import formatSlidingPaneData from './formatSlidingPaneData'

export const flattenList = ({ list, currentUserGroup, allGroups }) => {
  const formattedRows =
    list &&
    list.map((row) => {
      const assignmentHash = {}
      const locAssignments =
        row.location.assignments &&
        row.location.assignments.filter((assignment) =>
          currentUserGroup.some(
            (group) => assignment.assignedSlot.systemName === group.systemName,
          ),
        )
      const patAssignments =
        row.patient &&
        row.patient.assignments &&
        row.patient.assignments.filter((assignment) =>
          currentUserGroup.some(
            (group) => assignment.assignedSlot.systemName === group.systemName,
          ),
        )

      for (let i = 0; i < currentUserGroup.length; i++) {
        const currentLocAssignment =
          locAssignments &&
          locAssignments.filter((assignment) => {
            return (
              assignment.assignedSlot.systemName ===
              currentUserGroup[i].systemName
            )
          })[0]
        const currentLocUser = currentLocAssignment && currentLocAssignment.user
        const currentLocId = currentLocAssignment && currentLocAssignment.id

        const currentPatAssignment =
          patAssignments &&
          patAssignments.filter((assignment) => {
            return (
              assignment.assignedSlot.systemName ===
              currentUserGroup[i].systemName
            )
          })[0]
        const currentPatUser = currentPatAssignment && currentPatAssignment.user
        const currentPatId = currentPatAssignment && currentPatAssignment.id

        assignmentHash[currentUserGroup[i].systemName] = Object.assign(
          {},
          currentUserGroup[i],
          {
            assignedToLocation: {
              user:
                (currentLocUser &&
                  currentLocUser.firstName +
                    ' ' +
                    currentLocUser.lastName[0] +
                    '.') ||
                '',
              userId: (currentLocUser && currentLocUser.id) || '',
              assignmentId: currentLocId,
            },
            assignedToLocationPrint: {
              user:
                (currentLocUser &&
                  currentLocUser.firstName +
                    ' ' +
                    currentLocUser.lastName +
                    '') ||
                '',
              userId: (currentLocUser && currentLocUser.id) || '',
              assignmentId: currentLocId,
            },
            assignedToPatient: {
              user:
                (currentPatUser &&
                  currentPatUser.firstName +
                    ' ' +
                    currentPatUser.lastName[0] +
                    '.') ||
                '',
              userId: (currentPatUser && currentPatUser.id) || '',
              assignmentId: currentPatId,
            },
            assignedToPatientPrint: {
              user:
                (currentPatUser &&
                  currentPatUser.firstName +
                    ' ' +
                    currentPatUser.lastName +
                    ' ') ||
                '',
              userId: (currentPatUser && currentPatUser.id) || '',
              assignmentId: currentPatId,
            },
          },
        )
      }

      //trim fhirResourceUrl
      const splitFhirResourceUrl = row.location.fhirResourceUrl
        .split('-')
        .slice(0, 2)
        .join('-')

      const data = Object.assign({}, assignmentHash, {
        location: {
          bed: row.location.displayName,
          unit: row.location.unit.displayName,
          unitId: row.location.unit.id,
          id: row.location.id,
          status: row.location.status,
          unitFhirResourceUrl: splitFhirResourceUrl,
        },
        uuid: row.location.uuid,
        patient:
          row.patient !== null && row.patient !== undefined
            ? {
                name: `${row.patient.lastName}, ${row.patient.firstName}`.toUpperCase(),
                bed: row.location.displayName,
                meta: `${
                  row.patient.gender && row.patient.gender[0]
                } | ${moment(row.patient.dateOfBirth).format('MM/DD/YYYY')} | ${
                  row.patient.medicalRecordNumber
                }`,
                lastName: row.patient.lastName,
                id: row.patient.id,
                isolationStatus: row.patient.isolationStatus,
              }
            : {
                name: '',
                meta: '',
                id: '',
              },
        slidingPaneData: formatSlidingPaneData(allGroups, row),
      })
      return data
    })
  return formattedRows
}
