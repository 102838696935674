/**
 * External Imports
 */
import { NeuIcon, NeuButton } from '@neutron/react'
/**
 * Internal Imports
 */
import Checkbox from '../neutronComponents/checkbox/checkbox'

import {
  setGlobalUserSettings,
  toggleShowTutorial,
} from '../../../redux/actions/user'
/**
 * Style Imports
 */
import {
  OverwriteModal,
  ConfirmText,
  ButtonsDiv,
  CancelButton,
  AssignButton,
} from './overwritingAssignment.styles'
import { FlatTabButton } from '../styleLibrary'

const OverwritingAssignment = (props) => {
  const { showTutorial } = props.globalSettingsConfig.globalSettings

  const updateGlobalSettings = (bool) => {
    const { globalSettings } = props.globalSettingsConfig
    const updatedSettings = {
      ...globalSettings,
      showTutorial: !bool,
      showTutorial: !bool,
    }
    const settingsPackage = {
      ...props.globalSettingsConfig,
      globalSettings: updatedSettings,
    }

    props.dispatch(setGlobalUserSettings(settingsPackage))
  }

  const handleClick = () => {
    updateGlobalSettings(showTutorial)
    props.dispatch(toggleShowTutorial(!showTutorial))
  }

  const showHideOverlapClass = props.showOverlapAssignment
    ? 'modal display-block'
    : 'modal display-none'

  return (
    <div className={showHideOverlapClass}>
      <OverwriteModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <div className="neu-display-6 fw-bold">Assignment Override</div>
          <FlatTabButton
            onClick={() => {
              props.dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })
              props.hideOverlapModal()
            }}
          >
            <NeuIcon
              large
              color="gray"
              style={{
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmText>
            You have selected an assignment that overlaps with another staff
            member. Proceed?
          </ConfirmText>
        </div>

        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <Checkbox
            id="displayMessage"
            click={handleClick}
            isSelected={!showTutorial}
            custom={false}
          />

          <label className="neu-text-caption" style={{ marginLeft: '10px' }}>
            Do not show me this warning again
          </label>
        </div>

        <ButtonsDiv>
          <CancelButton
            kind="tertiary"
            color="red"
            onClick={() => {
              props.dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })
              props.hideOverlapModal()
            }}
          >
            Cancel
          </CancelButton>
          <AssignButton
            kind="primary"
            style={{
              fontSize: '16px',
            }}
            onClick={() => {
              updateGlobalSettings(!showTutorial)
              props.assignSlots()
            }}
          >
            Assign
          </AssignButton>
        </ButtonsDiv>
      </OverwriteModal>
    </div>
  )
}

export default OverwritingAssignment
