import styled from 'styled-components'

export const CloseHelpButton = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
`
export const AssignmentOverrideContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TitleAndCheckBoxContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 45px;
`
export const DescriptionContainer = styled.div`
  margin-right: 10px;
`
