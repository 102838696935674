import styled from 'styled-components'

export const LabelDisplay = styled.label`
  font-size: 14px;
  color: #1f2532;
`
export const LabelData = styled.div`
  font-size: 16px;
  margin-top: 5px;
`

export const Button = styled.div`
  display: flex;
  flex: 1 1 200px;
  height: 40px;
  margin: 0 5px;
  background-color: ${(props) => (props.save ? 'lightgrey;' : 'transparent')};
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  padding: 12px 15px;
  font-family: 'HCA-Mark', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 15px;
  border-radius: 5px;
  border: none;
`
export const SlidingPaneDiv = styled.div`
  transition: ${(props) =>
    props.prefersReducedMotion ? 'none' : 'right 0.3s ease-in-out'};
  position: absolute;
  top: 60px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 420px;
  z-index: 1;
  right: ${(props) => (props.prefersReducedMotion ? '0' : '-100vw')};

  &.show {
    right: 0;
  }

  &.hide {
    right: ${(props) => (props.prefersReducedMotion ? '0' : '-100vw')};
  }
`
