/**
 * External Import
 */
import styled from 'styled-components'
import { NeuPagination } from '@neutron/react'
/**
 * Internal Import
 */
import Flex from '../reusableComponents/styleLibrary'

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #bcbcbc;
  border-bottom: ${(props) => (props.last ? '1px solid #bcbcbc' : '')};
  position: relative;
  height: 88px;
`

export const ColumnDiv = styled.div`
  width: 120px;
  margin-left: 20px;
`

export const NameDiv = styled.div`
  color: #1f2532;
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InnerContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
`
export const ThreeFourDiv = styled.div`
  color: #1f2532;
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 16px !important;
  font-weight: 500;
  opacity: 0.7;
`

export const RoleDiv = styled.div`
  color: #1f2532;
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 16px !important;
  font-weight: 500;
`
export const ShowMoreDiv = styled.div`
  font-size: 14px !important;
`
export const ShowLessDiv = styled.div`
  font-size: 14px !important;
`

export const SiteAccessDiv = styled.div`
  color: #1f2532;
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
`

export const DisplayNameDiv = styled.div`
  color: rgba(5, 50, 134);
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
`

export const LocationDiv = styled.div`
  color: rgba(5, 50, 134);
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`
export const HiddenDiv = styled.div`
  font-size: 14px !important;

  font-weight: 500;
`
export const UnitContainer = styled.div`
  color: #1f2532;
  font-size: 14px;
  font-family: 'HCA-Mark';
  background: #f5f5f5 !important;
  border-bottom: 1px solid #bcbcbc;
`

export const UnitName = styled.div`
  font-family: HCA-Mark, Arial, sans-serif;
  background: #f5f5f5 !important;
  padding: 10px 10px 10px 15px;
  width: 100%;
  justify-content: flex-start;
`

export const BedDiv = styled.div`
  color: #1f2532;
  font-family: HCA-Mark, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
`

export const RemoveButton = styled.div`
  padding-right: 40px;
  color: #cc2c04;
  font-weight: 500;
  position: absolute;
  right: 0;
`

export const DraftSaved = styled.div`
  font-family: 'HCA-Mark';
  display: flex;
  justify-content: flex-end;
  width: 50%;
`

export const NoDataMessage = styled.div`
  background: white;
  font-size: 12px;
  font-family: 'HCA-Mark';
  padding: 10px 10px 10px 15px;
`

export const PaginationContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  color: #1f2433;
  font-size: 16px !important;
  font-family: 'HCA-Mark';
  height: 55px;
  position: sticky;
  bottom: 0;
  display: flex;
  border-top: 0.5px solid #e0e0e0;
`

export const PaginationLink = styled.div`
  cursor: pointer;
  color: rgb(0, 85, 140) !important;
  display: flex;
  align-items: center;
`

/**
 * Calculates the style object for a header in a table.
 * secondItemWidth is a temporary special case for the second column in the locationassignments table
 */
export function calculateHeaderStyle(props, header, columnHeaders, index) {
  let flexValue = `0 0 ${
    (((props.path === 'patients' &&
      (props.authorizedGlobalAdmin || props.authorizedLocalAdmin)) ||
    ((props.path === 'locationassignments' ||
      props.path === 'patientassignments') &&
      !props.ui.isDraftAssignment) ||
    (props.path !== 'patients' &&
      (props.authorizedGlobalAdmin || props.authorizedLocalAdmin))
      ? 92
      : 100) *
      header.multiplier) /
      columnHeaders.reduce(
        (acc, header, index) => acc + header.multiplier,
        0,
      ) || 0
  }%`

  const minWidth =
    props.path === 'locationassignments' &&
    index === 1 &&
    props.ui.departmentSelected === 'Nursing'
      ? '272px'
      : '0px'

  return {
    flex: `${flexValue}`,
    ...(header.id
      ? {
          display: 'flex',
          justifyContent: 'center',
        }
      : {}),
    ...(header.label === 'ASSIGNED TO BED' ? { marginLeft: '20px' } : {}),
    paddingLeft: '10px',
    minWidth,
    maxWidth:
      props.path === 'locationassignments' &&
      index === 1 &&
      props.ui.departmentSelected === 'Nursing'
        ? '300px'
        : undefined,
    paddingTop: '15px',
    paddingRight: '15px',
    paddingLeft:
      index === 0 &&
      !(
        props.path === 'locationassignments' ||
        props.path === 'patientassignments'
      )
        ? '15px'
        : header.label === 'BEDS'
          ? '10px'
          : '0px',
  }
}
