import styled from 'styled-components'

export const DeleteUserModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  left: 35%;
  top: 30%;
  height: 200px;
  border-radius: 5px;
  width: 420px;
  z-index: 2;
`

export const ConfirmDelete = styled.p`
  fontsize: 16px;
  line-height: 30px !important;
  letter-spacing: 0.2p;
  margin-bottom: 15px !important;
  margin-top: 7px !important;
`

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
`
