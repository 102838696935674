import React, { forwardRef } from 'react'

/**
 * External Imports
 */
import { NeuIcon } from '@neutron/react'
/**
 * Internal Imports
 */
import {
  setGlobalUserSettings,
  toggleShowTutorial,
} from '../../../redux/actions/user'
import Checkbox from '../neutronComponents/checkbox/checkbox'
import packageJson from '../../../../package.json'
/**
 * Style Imports
 */
import {
  AssignmentOverrideContainer,
  TitleAndCheckBoxContainer,
  CloseHelpButton,
  DescriptionContainer,
  CheckboxContainer,
} from './helpModal.styles'

const version = packageJson.version

const HelpModal = forwardRef(
  (
    { globalSettingsConfig, dispatch, showHideClassName, handleCloseButton },
    ref,
  ) => {
    const { showTutorial } = globalSettingsConfig.globalSettings

    const updateGlobalSettings = (bool) => {
      const { globalSettings } = globalSettingsConfig
      const updatedSettings = {
        ...globalSettings,
        showTutorial: !bool,
      }
      const settingsPackage = {
        ...globalSettingsConfig,
        globalSettings: updatedSettings,
      }

      dispatch(setGlobalUserSettings(settingsPackage))
    }

    const handleClick = () => {
      updateGlobalSettings(showTutorial)
      dispatch(toggleShowTutorial(!showTutorial))
    }

    return (
      <div className={showHideClassName}>
        <div
          className="modal-main"
          style={{
            padding: '10px',
            width: '615px',
            height: '645px',
            borderRadius: '5px',
            overflowY: 'scroll',
            display: 'block',
          }}
          ref={ref}
        >
          <div
            style={{
              marginBottom: '20px',
            }}
          >
            <div style={{ float: 'left' }}>
              <h6 style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
                Help
              </h6>
            </div>
            <div style={{ float: 'right' }} className="helpButtonClose">
              <CloseHelpButton onClick={handleCloseButton}>
                <NeuIcon large color="gray-50">
                  cancel
                </NeuIcon>
              </CloseHelpButton>
            </div>
          </div>

          <br />
          <div style={{ margin: '15px 0' }}>
            <div
              style={{
                fontFamily: 'HCA-Mark',
                fontWeight: '800',
                marginBottom: '2px',
              }}
            >
              Location Assignments -
            </div>
            <p className="neu-text-caption" style={{}}>
              When using the Location Assignments tab, a staff member will
              remain assigned to the selected bed until he/she is unassigned.
              When a patient is moved into that bed in the electronic medical
              record, the assigned staff member will display on the patient's
              care team in Mobile Heartbeat.
            </p>
          </div>
          <div
            // direction="column"
            style={{ margin: '15px 0' }}
          >
            <div style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
              Patient Assignments -
            </div>
            <p className="neu-text-caption" style={{}}>
              When using the Patient Assignments tab, a staff member will remain
              assigned to the selected patient until he/she unassigns within the
              application, or the patient is discharged. As a patient moves to
              different locations within the hospital, the assigned staff member
              will continue to be assigned to the patient's care team regardless
              of location.
            </p>
          </div>
          <div
            // direction="column"
            style={{ margin: '15px 0' }}
          >
            <div style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
              Snapshot Report -
            </div>
            <p className="neu-text-caption" style={{}}>
              A charge nurse or department supervisor can pull a snap shot of a
              previous day/night shift that displays patient, room, and assigned
              caregiver(s). Report return a list of beds (empty or filled),
              associated patient (if present), staff member and associated
              position. It does include all assignments made throughout the
              shift and may be helpful with creating the present shift’s
              assignments.
            </p>
          </div>
          <div style={{ margin: '15px  0' }}>
            <div style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
              Missing Assignments Report -
            </div>
            <p className="neu-text-caption" style={{}}>
              A nurse leader can search by unit and/or position (1N, 2N) for a
              list of patients (not empty beds) that have not had an assignment
              for 10 minutes or more. At this time, it is only scoped for
              positions on the bedside nursing team (1N, 2N, PCT, CNC) and can
              be used to ensure compliance of timely assignments.
            </p>
          </div>
          <div style={{ margin: '10px 0' }}>
            <div style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
              Assignment Audit Report -
            </div>
            <p className="neu-text-caption">
              A system administrator can pull a list of CTA-created events based
              on search criteria. It includes room, patient (if present) and
              associated staff names, and may be useful for troubleshooting
              assignments in downstream systems like Mobile Heartbeat or Nurse
              Call. Search history is based on the duration of data retained in
              the application and may vary.
            </p>
          </div>
          <AssignmentOverrideContainer>
            <TitleAndCheckBoxContainer>
              <div style={{ fontFamily: 'HCA-Mark', fontWeight: '800' }}>
                Assignment Override pop-up -
              </div>
              <CheckboxContainer>
                <Checkbox
                  id="displayMessage"
                  color="primary"
                  click={handleClick}
                  isSelected={showTutorial}
                  custom={false}
                />
                <label style={{ marginLeft: '5px' }} htmlFor="displayMessage">
                  On
                </label>
              </CheckboxContainer>
            </TitleAndCheckBoxContainer>
            <DescriptionContainer>
              <p className="neu-text-caption">
                A pop up notification that appears when you are overriding an
                assignment. If you do not wish to see this pop up, uncheck the
                box.
              </p>
            </DescriptionContainer>
          </AssignmentOverrideContainer>
          <div style={{ textAlign: 'center', marginTop: '60px' }}>
            <p>v{version}</p>
          </div>
        </div>
      </div>
    )
  },
)

export default HelpModal
