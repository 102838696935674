import styled from 'styled-components'

export const SlidingPaneDiv = styled.div`
  transition: ${(prefersReducedMotion) =>
    prefersReducedMotion ? 'none' : 'right 0.3s ease-in-out'};
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  right: ${(prefersReducedMotion) => (prefersReducedMotion ? '0' : '-100vw')};
  height: 100%;
  width: 420px;
  z-index: 2;

  &.show {
    right: 0;
  }
  &.hide {
    right: ${(prefersReducedMotion) => (prefersReducedMotion ? '0' : '-100vw')};
  }
`
