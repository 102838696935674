/**
 * External Imports
 * */
import { useState, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import { debounce } from 'lodash'
import _ from 'lodash'

/**
 * Internal Imports
 * */
import Table from './table'
import Events from '../../analytics/events'
import PrintCSV from '../reusableComponents/print/PrintCSV'
import PrintPDF from '../reusableComponents/print/PrintPDF'
import Loading from '../reusableComponents/neutronComponents/Spinner'
//TODO: Fix Camel Case Below
import unitandUserGroupDropdownSelector from '../../redux/selectors/unitandUserGroupDropdownSelector'

import { formatSort } from '../../utils/naturalSort'
import { filterLocations } from '../../utils/filterLocations'
import { flattenList } from '../../utils/flattenList'
/**
 * Styling Imports
 * */
import { Container, SearchContainer } from './container.styles'
import { createSelector } from 'reselect'

const TableContainer = (props) => {
  const [, setInitialSort] = useState(true)
  const [sortDirection, setSortDirection] = useState(true)
  const [sortBy, setSortBy] = useState('location')

  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const listByPage = () => {
    const _list = props.ui.searching
      ? props.ui.bedsSearchResults
      : props.locations.locations
    const draftList = props.ui.searching
      ? props.ui.draftBedSearchResults
      : props.draftAssignments.draftLocations

    switch (props.page) {
      case 'locations':
        if (props.ui.isDraftAssignment) {
          return draftList
        } else {
          return _list
        }
      case 'patients':
        return _list.filter((item) => item.patient)
      default:
        return
    }
  }

  const printableList = () => {
    const currentUserGroup =
      props.staffSlotsPerRoleGroup[props.selectedUserGroupName]
    const sortDirectionString = sortDirection ? 'ASC' : 'DESC'
    const list = listByPage()
    return formatSort(
      flattenList({
        list,
        currentUserGroup,
        allGroups: props.staffSlotsPerRoleGroup,
      }),
      props.page === 'locations' ||
        (props.page === 'patients' && !(sortBy === 'patient'))
        ? 'bed'
        : props.page === 'patients' && sortBy === 'patient'
          ? 'name'
          : 'unit',
      sortDirectionString,
      props.page.slice(0, props.page.length - 1),
    )
  }

  const filterResults = (searchText) => {
    const locationSearch = props.locations.locations.filter((location) => {
      const displayName =
        location.location && location.location.displayName.toLowerCase()
      const sanitizedSearch = searchText.toLowerCase().trim()

      return displayName && displayName.includes(sanitizedSearch)
    })

    const firstNameSearch = props.locations.locations.filter((location) => {
      const firstName =
        location.patient && location.patient?.firstName?.toLowerCase()
      const sanitizedSearch = searchText.toLowerCase().trim()

      return firstName && firstName.includes(sanitizedSearch)
    })

    const lastNameSearch = props.locations.locations.filter((location) => {
      const lastName =
        location.patient && location.patient?.lastName?.toLowerCase()

      const sanitizedSearch = searchText.toLowerCase().trim()

      return lastName && lastName.includes(sanitizedSearch)
    })

    const fullNameSearch = (searchText) => {
      const sanitizedSearchText = searchText
        .toLowerCase()
        .replace(/,\s*/g, ' ')
        .replace(/\s+/g, ' ')
        .trim()

      return props.locations.locations.filter((location) => {
        if (!location.patient) return false

        const firstName =
          location.patient?.firstName?.toLowerCase().trim() || ''
        const lastName = location.patient?.lastName?.toLowerCase().trim() || ''

        const fullName = `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim()
        const reverseFullName = `${lastName} ${firstName}`
          .replace(/\s+/g, ' ')
          .trim()

        return (
          fullName.includes(sanitizedSearchText) ||
          reverseFullName.includes(sanitizedSearchText)
        )
      })
    }

    const fullNameSearchResults = fullNameSearch(searchText)

    const results = _.uniq([
      ...locationSearch,
      ...firstNameSearch,
      ...lastNameSearch,
      ...fullNameSearchResults,
    ])
    return results
  }

  const handleSearch = debounce((value) => {
    const searchText = value
    if (!searchText) {
      props.dispatch({
        type: 'locations/CLEAR_SEARCH',
      })
      if (props.page === 'patients') {
        setInitialSort({ initialSort: true })
      }
    } else {
      if (props.ui.isDraftAssignment) {
        props.dispatch({
          type: 'locations/SET_SEARCH',
          payload: {
            searching: true,
            bedsSearchResults: filterLocations(
              searchText,
              props.locations.locations,
            ),
            draftBedSearchResults: filterLocations(
              searchText,
              props.draftAssignments.draftLocations,
            ),
            searchText: searchText,
            page: props.page,
          },
        })
      } else {
        props.dispatch({
          type: 'locations/SET_SEARCH',
          payload: {
            searching: true,
            bedsSearchResults:
              props.page === 'patients'
                ? filterResults(searchText)
                : filterLocations(searchText, props.locations.locations),
            draftBedSearchResults:
              props.page === 'patients'
                ? []
                : filterLocations(
                    searchText,
                    props.draftAssignments.draftLocations,
                  ),
          },
        })
        if (props.page === 'patients') {
          setInitialSort({ initialSort: true })
        }
      }
    }
  }, 50)

  // createSelector from reselect to memoize the selector and avoid terminal warning
  const selectLockedRoleConfig = createSelector(
    (state) => state.groupAndStaffSlotMetaData?.unitLevelLockedRoles || [],
    (unitLevelLockedRoles) => unitLevelLockedRoles,
  )

  const lockedRoleConfig = useSelector(selectLockedRoleConfig)

  const unitLevelLockedRoles = useMemo(
    () => lockedRoleConfig?.unitLevelLockedRoles,
    [lockedRoleConfig],
  )
  const unitLevelLockedRoleUrls = useMemo(
    () => unitLevelLockedRoles?.map((role) => role.fhirResourceUrl),
    [unitLevelLockedRoles],
  )
  const fhirResourceUrls = useMemo(
    () =>
      props.locations.locations.map((location) => {
        const url = location.location.fhirResourceUrl
        const parts = url.split('-')
        return parts.length > 2 ? `${parts[0]}-${parts[1]}` : url
      }),
    [props.locations.locations],
  )
  // getting list of fhirResourceUrls that are not locked
  const filteredFhirResourceUrls = useMemo(
    () =>
      fhirResourceUrls.filter((url) => !unitLevelLockedRoleUrls?.includes(url)),
    [fhirResourceUrls, unitLevelLockedRoleUrls],
  )

  const printTable = () => {
    let locations = props.locations
    let bedErrorStatus = locations && locations.error && locations.error.status

    if (props.draftAssignments.draftIsLoading) {
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading square="100px" />
        </div>
      )
    } else if (locations && locations.fetching) {
      return (
        <div
          style={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading square="100px" />
        </div>
      )
    } else if (bedErrorStatus === 404 && locations && locations.locations < 1) {
      return (
        <div
          style={{
            paddingTop: '50px',
            paddingLeft: '300px',
            paddingBottom: '50px',
            fontSize: '20px',
          }}
        >
          No active beds. For concerns, contact local administrator.
        </div>
      )
    } else {
      return (
        <Table
          authToken={props.authToken}
          draftAssignments={props.draftAssignments.draftAssignments}
          selectedFacilityCoid={props.facilityCoid}
          facilities={props.facilities}
          fetchingLockedRoles={
            props.groupAndStaffSlotMetaData.fetchingLockedRoles
          }
          selectedUnitIds={props.units.selectedUnitIds}
          selectedUnits={props.units.units}
          stagedStaffSlots={props.stagedStaffSlots}
          filteredFhirResourceUrls={filteredFhirResourceUrls}
          selectedStaffMember={props.selectedStaffMember}
          selectedUserGroupName={props.selectedUserGroupName || ''}
          staffSlots={props.staffSlots}
          siteId={props.siteId}
          list={listByPage()}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortDirection={setSortDirection}
          staffSlotsPerRoleGroup={props.staffSlotsPerRoleGroup}
          ui={props.ui}
          unitLevelLockedRoles={unitLevelLockedRoles}
          selectedStaffSlots={props.selectedStaffSlots.selectedStaffSlots}
          currentlySelectedRows={props.ui.currentlySelectedRows}
          page={props.page}
          fetchingLocations={locations.fetching}
          path={
            props.page === 'locations'
              ? 'locationassignments'
              : 'patientassignments'
          }
        />
      )
    }
  }
  /**
   * Tracking
   */
  const trackingClicks = () => {
    const ctaLastPath = localStorage.getItem('ctaLastPath')
    if (
      !props.ui.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName:
            Events.Locations.Screen[0].Events.Unit_Patient_Search_FieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      props.ui.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName:
            Events.Locations.Screen[1].Events.Unit_Patient_Search_FieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (ctaLastPath.includes('patientassignments')) {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName:
            Events.Patients.Screen.Events.Unit_Patient_Search_FieldClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  return (
    <Container>
      <div className="flex" style={{ marginTop: '15px', width: '100%' }}>
        <SearchContainer>
          <div style={{ minWidth: '200px' }} className="w-100">
            <div className="ui left icon input Patient-Search w-100 mt2">
              <i className="search icon" />
              <input
                type="text"
                style={{ width: '100%', height: '44px', paddingLeft: '38px' }}
                placeholder="Search unit and patient"
                onClick={() => trackingClicks()}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </div>
            <div className="results" />
          </div>
          <div style={{ display: 'flex', paddingTop: '8px' }}>
            {props.user.userPermissions.includes('PrintAssignments') &&
              !props.ui.isDraftAssignment && (
                <>
                  <PrintCSV
                    staffSlots={props.staffSlots}
                    list={printableList()}
                    selectedUnitIds={props.selectedUnitIds}
                    page={props.page}
                  />
                  <PrintPDF
                    staffSlots={props.staffSlots}
                    list={printableList()}
                    page={props.page}
                  />
                </>
              )}
          </div>
        </SearchContainer>
      </div>
      {printTable()}
    </Container>
  )
}

const mapReduxStateToProps = function (state) {
  return {
    ...state,
    ...unitandUserGroupDropdownSelector(state),
  }
}

export default connect(mapReduxStateToProps)(TableContainer)
