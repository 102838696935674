import moment from 'moment'
import _ from 'lodash'
import formatSort from './naturalSort'
import { v4 as uuid } from 'uuid'
import { naturalSort } from './naturalSort'

export const formatManageLocation = (props) => {
  const locationList = []
  props.locations &&
    props.locations.forEach((location) => {
      let numberOfHiddenUnits
      let numberOfActiveUnits
      let numberOfHiddenRooms
      let totalNumberOfRooms
      let numberOfHiddenBeds
      let totalNumberOfBeds
      let totalNumberOfUnits
      if (props.locationType === 'Site') {
        numberOfHiddenUnits = location.hiddenUnits
        numberOfActiveUnits = location.activeUnits
        totalNumberOfUnits = numberOfHiddenUnits + numberOfActiveUnits
        numberOfHiddenRooms = location.hiddenRooms
        totalNumberOfRooms = location.hiddenRooms + location.activeRooms
        numberOfHiddenBeds = location.hiddenBeds
        totalNumberOfBeds = location.hiddenBeds + location.activeBeds
      }
      if (props.locationType === 'Unit') {
        numberOfHiddenRooms = location.hiddenRooms
        totalNumberOfRooms = location.hiddenRooms + location.activeRooms
        numberOfHiddenBeds = location.hiddenBeds
        totalNumberOfBeds = location.hiddenBeds + location.activeBeds
      }
      if (props.locationType === 'Room') {
        numberOfHiddenBeds = location.hiddenBeds
        totalNumberOfBeds = location.hiddenBeds + location.activeBeds
      }

      locationList.push({
        displayName: location.displayName,
        facilityCoid: location.facilityCoid,
        siteId: location.siteId,
        status: location.status,
        id: location.id,
        uuid: uuid(),
        numberOfUnits: totalNumberOfUnits,
        numberOfHiddenUnits,
        numberOfActiveUnits,
        numberOfHiddenRooms,
        totalNumberOfRooms,
        totalNumberOfBeds,
        numberOfHiddenBeds,
        integrationProperties: location.integrationProperties,
        locationType: location.locationType,
        assignable: location.assignable,
        parent: location.parent,
        partitionKey: location.partitionKey,
        type: location.type,
        unit: location.unit || null,
        fhirResourceUrl: location.fhirResourceUrl || '',
      })
    })
  const hash = []
  locationList.forEach((item) => {
    hash.push(item['displayName'].toUpperCase())
  })
  const orderedHash = hash.sort(naturalSort)
  const orderedList = []
  for (let i = 0; i < orderedHash.length; i++) {
    for (let j = 0; j < locationList.length; j++) {
      if (orderedHash[i] === locationList[j]['displayName'].toUpperCase()) {
        orderedList.push(locationList[j])
      }
    }
  }

  return formatSort(locationList, 'displayName', 'ASC', props.path)
}

export const formatManagePatientsList = (list, page) => {
  const patientList = []
  list &&
    list.forEach((item) => {
      if (
        item.patient !== null &&
        item.patient !== undefined &&
        item.patient.location &&
        item.patient.location.unit
      ) {
        patientList.push({
          name: `${item.patient.lastName}, ${item.patient.firstName}`,
          meta: `${item.patient.gender && item.patient.gender[0]} | ${moment(
            item.patient.dateOfBirth,
          ).format('MM/DD/YYYY')} | ${item.patient.medicalRecordNumber}`,
          id: item.patient.id,
          bed: `${item.location.displayName}`,
          bedId: item.location.id,
          uuid: uuid(),
          unitId: item.patient.location.unit.id,
        })
      }
    })
  return formatSort(patientList, 'bed', 'ASC', page)
}

export const formatManageBedsList = (formattedBedsList, page) => {
  const bedList = []

  formattedBedsList.forEach((bed) => {
    bedList.push({
      bed: bed.displayName,
      id: bed.id,
      status: bed.status,
      uuid: uuid(),
    })
  })

  return formatSort(bedList, 'bed', 'ASC', page)
}
