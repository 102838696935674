const initialState = {
  divisions: [],
  enterpriseAdminDivisions: [],
  fetchingEnterpriseAdminDivisions: [],
  patientDivisions: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'divisions/DIVISIONS_RECEIVED':
      return { ...state, divisions: action.payload }
    case 'divisions/ENTERPRISE_ADMIN_DIVISIONS_RECEIVED':
      return { ...state, enterpriseAdminDivisions: action.payload }
    case 'divisions/PATIENT_DIVISIONS_RECEIVED':
      return { ...state, patientDivisions: action.payload }
    default:
      return state
  }
}
