import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

export const SlidingPaneDiv = styled.div`
  transition: ${(prefersReducedMotion) =>
    prefersReducedMotion ? 'none' : 'right 0.3s ease-in-out'};
  background-color: white;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: ${(prefersReducedMotion) => (prefersReducedMotion ? '0' : '-100vw')};
  width: 420px;
  z-index: 2;

  &.show {
    left: 0;
  }
  &.hide {
    left: ${(prefersReducedMotion) => (prefersReducedMotion ? '0' : '-100vw')};
  }
`

export const CancelButton = styled(NeuButton)`
  width: 145px !important;
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: #005589 !important;

  &:hover {
    background-color: #cdd1d9 !important;
  }
`
export const RunButton = styled(NeuButton)`
  width: 145px !important;
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: white !important;
  background-color: #005589 !important;
`
