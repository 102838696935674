/**
 * External Imports
 * */
import { useState, useEffect } from 'react'

import { NeuTable } from '@neutron/react'
import { useSelector } from 'react-redux'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

/**
 * Internal Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import printPatients from '../reusableComponents/printPatients'
import PrintLocations from '../reusableComponents/printLocations'
import AssignedLocationDisplay from '../manage/users/AssignedLocationDisplay'
import { dischargePatient } from '../../redux/actions/patients'
import { getSingleLocation } from '../../redux/actions/locations'
import { getClinicalRolesForAddUser } from '../../redux/actions/groupAndStaffSlotMetaData'

import Events from '../../analytics/events'
import { fetchEnterpriseAdminDivisions } from '../../redux/actions/divisions'
/**
 * Styling Imports
 */
import {
  ColumnDiv,
  DisplayNameDiv,
  LocationDiv,
  HiddenDiv,
  BedDiv,
  RoleDiv,
  ThreeFourDiv,
  NameDiv,
  RemoveButton,
} from './table.styles'

const DataList = (props) => {
  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)
  const [isLoading, setIsLoading] = useState(true)
  let listData = props.list

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  const renderNoDataMessage = (message) => {
    return (
      <div
        style={{
          marginLeft: '30px',
          fontSize: '18px',
          fontWeight: '500',
          paddingTop: '10px',
        }}
      >
        {message}
      </div>
    )
  }

  if (!isLoading) {
    if (props.path === 'users' && props.list.length === 0 && !props.fetchingUsers) {
      return renderNoDataMessage('No users found.')
    } else if (props.path === 'units' && props.list.length < 1) {
      return renderNoDataMessage('No units found.')
    } else if (props.path === 'rooms' && props.list.length < 1) {
      return renderNoDataMessage('No rooms found.')
    } else if (props.path === 'sites' && props.list.length < 1) {
      return renderNoDataMessage('No sites found.')
    } else if (props.path === 'beds' && props.list.length < 1) {
      return renderNoDataMessage('No beds found.')
    } else {
      listData = props.list
    }
  }
  const handleSlidingPane = (data) => {
    if (props.path === 'users') {
      const authToken = props.authToken
      const siteId = data.siteId
      props.dispatch(fetchEnterpriseAdminDivisions(authToken))
      props.dispatch(
        getClinicalRolesForAddUser({
          authorization: authToken,
          siteId,
        }),
      )
      props.dispatch({
        type: 'ui/MANAGE_SLIDING_PANE',
        payload: {
          open: true,
          source: props.path,
          data: data,
        },
      })
    } else {
      props.dispatch(
        getSingleLocation({
          authToken: props.authToken,
          siteId:
            props.path === 'sites'
              ? data.siteId
              : props.selectedAdminSite?.value,
          locationId: data.id,
        }),
      )
      props.dispatch({
        type: 'ui/MANAGE_SLIDING_PANE',
        payload: {
          open: true,
          source: props.path,
          data: data,
        },
      })

      if (props.path === 'sites') {
        props.handleLocationsTableSelection(data, 'Sites')
      }
    }
  }

  return (
    listData?.length > 0 &&
    listData.map((data, index) => {
      let formattedData
      switch (props.path) {
        case 'users':
          formattedData = [
            <div>
              <NameDiv
                className="neu-text-body neu-text-body fw-bold neu-text-gray-90"
                style={{
                  color: '#1F2532',
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                }}
              >
                {data.lastName + ', ' + data.firstName}
              </NameDiv>
              <ThreeFourDiv>{data.hca34.toUpperCase()}</ThreeFourDiv>
            </div>,
            <RoleDiv>
              {(data.clinicalRole && data.clinicalRole.displayName) || ''}
            </RoleDiv>,
            <AssignedLocationDisplay
              assignedLocations={data.assignedLocations}
            />,
          ]
          break
        case 'patientassignments':
          formattedData = printPatients(data, props)

          break
        case 'locationassignments':
          formattedData = PrintLocations(data, props)

          break
        case 'sites':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Units')
                }
              >
                {data.numberOfUnits}{' '}
                {data.numberOfUnits === 1 ? (
                  <span>Unit</span>
                ) : (
                  <span>Units</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenUnits > 0 && (
                <HiddenDiv>({data.numberOfHiddenUnits} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Rooms')
                }
              >
                {data.totalNumberOfRooms}{' '}
                {data.totalNumberOfRooms === 1 ? (
                  <span>Room</span>
                ) : (
                  <span>Rooms</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenRooms > 0 && (
                <HiddenDiv>({data.numberOfHiddenRooms} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <HiddenDiv>({data.numberOfHiddenBeds} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
          ]
          break
        case 'units':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <HiddenDiv>{data.status}</HiddenDiv>
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Rooms')
                }
              >
                {data.totalNumberOfRooms}{' '}
                {data.totalNumberOfRooms === 1 ? (
                  <span>Room</span>
                ) : (
                  <span>Rooms</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenRooms > 0 && (
                <HiddenDiv>({data.numberOfHiddenRooms} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <HiddenDiv>({data.numberOfHiddenBeds} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
          ]
          break
        case 'rooms':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <HiddenDiv>{data.status}</HiddenDiv>
            </ColumnDiv>,
            <ColumnDiv>
              <LocationDiv
                onClick={() =>
                  props.handleLocationsTableSelection(data, 'Beds')
                }
              >
                {data.totalNumberOfBeds}{' '}
                {data.totalNumberOfBeds === 1 ? (
                  <span>Bed</span>
                ) : (
                  <span>Beds</span>
                )}
              </LocationDiv>
              {data.numberOfHiddenBeds > 0 && (
                <HiddenDiv>({data.numberOfHiddenBeds} Hidden)</HiddenDiv>
              )}
            </ColumnDiv>,
          ]
          break
        case 'beds':
          formattedData = [
            <DisplayNameDiv>{data.displayName}</DisplayNameDiv>,
            <ColumnDiv>
              <HiddenDiv>{data.status}</HiddenDiv>
            </ColumnDiv>,
          ]
          break
        case 'patients':
          formattedData = [
            <BedDiv>{data.bed}</BedDiv>,
            <div
              style={{
                display: 'column',
                fontSize: 12,
                color: '#1F2532',
                fontWeight: '400',
                paddingTop: '-10px',
              }}
            >
              <div>{data.name}</div>
              <div>{data.meta}</div>
            </div>,
          ]
          break
        default:
          break
      }

      return (
        <NeuTable unit="row" key={index} style={{ width: '100%' }}>
          {formattedData.map((val, i) => (
            <NeuTable
              unit="column"
              key={i}
              style={{
                width: '100%',
                display: 'flex',
              }}
            >
              <span
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent:
                    props.path === 'locationassignments' && i >= 2 && i <= 5
                      ? 'center'
                      : 'undefined',
                  alignItems:
                    props.path === 'locationassignments' && i >= 2 && i <= 5
                      ? 'center'
                      : 'undefined',
                }}
              >
                {val}
              </span>
            </NeuTable>
          ))}
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'end' }}
          >
            <NeuTable unit="column" style={{ width: '100%', display: 'flex' }}>
              {props.path === 'patients' &&
                (props.authorizedGlobalAdmin || props.authorizedLocalAdmin) && (
                  <RemoveButton
                    className="cursor"
                    onClick={() => {
                      logTrackingEvent([
                        {
                          module: Events.Manage.Module,
                          screen: Events.Manage.Screen[2].Name,
                          eventName:
                            Events.Manage.Screen[2].Events.Remove_ButtonClick,
                          eventType: 'action',
                          org: {
                            orgId: orgId,
                            orgLevel: 'Facility',
                          },
                        },
                      ])

                      props.dispatch(
                        dischargePatient(
                          props.authToken,
                          props.selectedAdminSite?.value,
                          data.id,
                          data.unitId,
                        ),
                      )
                    }}
                  >
                    Remove
                  </RemoveButton>
                )}
              {(props.path === 'locationassignments' ||
                props.path === 'patientassignments') &&
                !props.ui.isDraftAssignment && (
                  <Flex
                    style={{
                      height: '100%',
                      marginBottom: '10px',
                      justifyContent: 'right',
                      paddingRight: '20px',
                    }}
                    onClick={() => {
                      props.dispatch({
                        type: 'ui/ASSIGNMENT_SLIDING_PANE',
                        payload: {
                          open: true,
                          source: props.path.slice(0, props.path.length - 1),
                          data: data.slidingPaneData,
                          userExists: false,
                        },
                      })
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: 'max-content',
                        alignItems: 'center',
                        paddingRight: '10px',
                      }}
                    >
                      <div
                        style={{
                          justifyContent: 'end',
                          width: '100%',
                          fontWeight: 'bold',
                          textAlign: 'right',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                        className="neu-display-5 neu-text-gray-40"
                      >
                        ...
                      </div>
                    </div>
                  </Flex>
                )}
              {props.path !== 'patients' &&
                (props.authorizedGlobalAdmin || props.authorizedLocalAdmin) && (
                  <span>
                    <div
                      className="cursor"
                      style={{
                        paddingRight: '20px',
                        color: 'rgba(5,50,134)',
                        fontFamily: 'HCA-Mark, Arial, sans-serif',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        handleSlidingPane(data)
                      }}
                    >
                      <i className="material-icons">edit</i>
                      Edit
                    </div>
                  </span>
                )}
            </NeuTable>
          </div>
        </NeuTable>
      )
    })
  )
}

export default DataList
