import { publishDraftAssignments } from '../../../redux/actions/draftAssignments'

// styles
import { NeuIcon, NeuButton } from '@neutron/react'
import { FlatTabButton } from '../styleLibrary'
import {
  PublishDraftModal,
  ConfirmText,
  CancelButton,
  PublishButton,
} from './publishDraftModal.styles'

const AssigningToAllUnits = ({
  showPublishModalState,
  dispatch,
  hidePublishModal,
  authToken,
  siteId,
  selectedUnitIds,
  ui,
  draftLocations,
  handlePublishState,
}) => {
  const showAssignToAllClass = showPublishModalState
    ? 'modal display-block'
    : 'modal display-none'

  return (
    <div className={showAssignToAllClass}>
      <PublishDraftModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
        >
          <div className="neu-display-6 fw-bold">
            Publishing Draft Assignment
          </div>
          <FlatTabButton
            onClick={() => {
              dispatch({
                type: 'stagedStaffSlots/ASSIGNING',
                payload: false,
              })

              hidePublishModal()
            }}
          >
            <NeuIcon
              color="gray"
              large
              style={{
                cursor: 'pointer',
              }}
            >
              cancel
            </NeuIcon>
          </FlatTabButton>
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <ConfirmText>
            This will now replace all current assignments, please confirm.
          </ConfirmText>
        </div>

        <div
          className={'navItemText'}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '20px',
          }}
        >
          <CancelButton
            kind="tertiary"
            color="red"
            large
            onClick={() => {
              hidePublishModal()
            }}
          >
            Cancel
          </CancelButton>

          <PublishButton
            kind="primary"
            color="blue"
            size="default"
            onClick={() => {
              dispatch(
                publishDraftAssignments(
                  authToken,
                  siteId,
                  selectedUnitIds,
                  ui,
                  draftLocations,
                ),
              )
              handlePublishState()
              hidePublishModal(false)
            }}
          >
            Publish
          </PublishButton>
        </div>
      </PublishDraftModal>
    </div>
  )
}

export default AssigningToAllUnits
