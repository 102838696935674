/**
 * Internal Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import { Chip, Bubble } from './neutronComponents/chip/chip'
import IsolationStatusBadge from '../table/IsolationStatusBadge'

export default function printPatients(data, props) {
  const patientChipSlots =
    props.selectedUserGroupName !== 'Nursing'
      ? props.props.staffSlots.map((slot) => {
          const assignment = data[slot.systemName]

          // Error check
          if (!assignment || !assignment.systemName) {
            console.error('Assignment or systemName is undefined:', assignment)
            return null
          }

          // Check if the assignment is disabled
          const isDisabled = props.disabled.some((item) => {
            const isMatch =
              (data.location.unitFhirResourceUrl === item.fhirResourceUrl &&
                item.lockedRoles.includes(assignment.systemName)) ||
              props.disabled.some((chip) => chip === slot.systemName)

            return isMatch
          })

          const stagedStaffSlot =
            props.stagedStaffSlots &&
            props.stagedStaffSlots.length > 0 &&
            props.stagedStaffSlots.filter(
              (slot) =>
                assignment.systemName ===
                  slot.manifest.AssignmentSlot.SystemName &&
                slot.uuid === data.uuid,
            )[0]
          const assignedUser = stagedStaffSlot
            ? stagedStaffSlot &&
              stagedStaffSlot.assignedToPatient &&
              stagedStaffSlot &&
              stagedStaffSlot.assignedToPatient.user
            : assignment &&
              assignment.assignedToPatient &&
              assignment &&
              assignment.assignedToPatient.user
          const currentRowFromState = props.list.filter((row) => {
            // if list has a user assigned to this role
            return row.uuid === data.uuid
          })[0]

          const currentUserFromState =
            currentRowFromState[slot.systemName] &&
            currentRowFromState[slot.systemName].assignedToPatient &&
            currentRowFromState[slot.systemName].assignedToPatient.user

          const isFreshAssignment = !(
            currentUserFromState && currentUserFromState.length > 0
          )
          return (
            <Chip
              action={stagedStaffSlot && stagedStaffSlot.action}
              staged={stagedStaffSlot}
              isFreshAssignment={isFreshAssignment}
              assignedUser={assignedUser}
              assignmentText={
                (assignedUser && assignedUser) ||
                (assignment && assignment.abbreviation)
              }
              roleText={assignment.abbreviation}
              selected={
                props.selectedStaffSlots.length > 0 &&
                props.selectedStaffSlots.filter(
                  (chip) =>
                    chip.manifest.AssignmentSlot &&
                    chip.manifest.AssignmentSlot.SystemName ===
                      (assignment && assignment.systemName) &&
                    chip.uuid === data.uuid,
                ).length > 0
              }
              isDisabled={isDisabled}
              click={() => {
                props.selectChip({ data, assignment })
              }}
              context={data.rowIndex === props.list.length - 1 ? 'last' : ''}
            />
          )
        })
      : []
  const locationAssignmentBubbles = []
  props.staffSlots.forEach((_slot) => {
    if (
      data[_slot.systemName] &&
      data[_slot.systemName].assignedToLocation &&
      data[_slot.systemName].assignedToLocation.user
    ) {
      locationAssignmentBubbles.push(data[_slot.systemName].abbreviation)
    } else {
      locationAssignmentBubbles.push('___')
    }
  })
  const formattedData = [
    <Flex>
      <Flex
        style={{
          fontSize: 12,
          color: '#1F2532',
          width: '180px',
        }}
        direction="column"
      >
        <Flex
          style={{
            fontWeight: '500',
            fontFamily: 'HCA-Mark',
            width: '160px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 14,
            paddingBottom: '5px',
            display: 'block',
          }}
          className="neu-text-body neu-text-body fw-bold neu-text-gray-90"
        >
          {data.patient.name}
        </Flex>
        <Flex>
          {data.patient.meta}
          <IsolationStatusBadge
            isolationStatusList={data.patient.isolationStatus}
            keyProp={data.patient.id}
          />
        </Flex>
      </Flex>
    </Flex>,
    <Flex
      style={{
        fontSize: 12,
        color: '#1F2532',
        fontWeight: '400',
        width: '160px',
        margin: '10px 0',
      }}
    >
      {data.location.bed}
    </Flex>,
    <Flex style={{ marginTop: 20, position: 'absolute' }}>
      {props.staffSlots.map((_slot, index) => {
        return (
          <Bubble
            text={locationAssignmentBubbles[index]}
            key={data.uuid + index}
          />
        )
      })}
    </Flex>,
  ]
  for (let i = 0; i < patientChipSlots.length; i++) {
    const itemIndex = 2 + i
    formattedData.splice(itemIndex, 0, patientChipSlots[i])
  }
  return formattedData
}
