import axios from '../../utils/axiosWrapper'
import environment from '../../environment'

const serverUrl = environment._serverUrl

export const fetchEnterpriseAdminDivisions = (authToken) => (dispatch) => {
  let urlForAuthUser

  urlForAuthUser = serverUrl + `User/GetEnterpriseAdminLocations`

  dispatch({ type: 'addLocations/FETCH_ALL_LOCATIONS' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      Pragma: 'no-cache',
    },
  })
    .catch(function (error) {
      console.log(error.response)
    })
    .then(function (response) {
      dispatch({
        type: 'divisions/ENTERPRISE_ADMIN_DIVISIONS_RECEIVED',
        payload: response.data,
      })
    })
}
