export const formatDropdownData = (list) => {
  if (list.length < 1) return []
  return list
    .map((item, index) => ({
      key: index,
      value: item.id,
      text: item.displayName.trim(),
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
}

export const formatClinicalRoleDropdownData = (list) => {
  if (list.length < 1) return []
  return list
    .map((item, index) => ({
      key: index,
      value: item.id,
      text: item.displayName,
      systemname: item.systemName,
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
}
export const formatSiteDropdownData = (list) => {
  if (list.length < 1) return []
  return list
    .map((item, index) => ({
      key: index,
      facilityCoid: item.facilityCoid,
      parent: item.parent,
      value: item.siteId,
      text: item.displayName,
      id: item.id,
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
}

export const formatDivisionDropdownData = (list) => {
  if (list.length < 1) return []
  return list
    .map((item, index) => ({
      id: item.id,
      index,
      displayname: item.displayName,
      text: item.displayName,
      value: item.id,
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
}
