/**
 * External Imports
 * */
import { useCallback, useEffect, useState, useRef } from 'react'
import { NavLink, withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { Dropdown } from 'semantic-ui-react'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'

/**
 * Internal Imports
 * */
import { selectSite } from '../../../redux/actions/sites'
import Events from '../../../analytics/events'
import {
  clearLockedRoles,
  fetchLockedRoles,
  fetchLockedRoleConfig,
  fetchUserGroups,
  getClinicalRoles,
} from '../../../redux/actions/groupAndStaffSlotMetaData'
import {
  fetchLocationsWithMultipleUnits,
  fetchAllAssignments,
} from '../../../redux/actions/locations'
import { clearReportData } from '../../../redux/actions/reports'
import { clearAllSelectedLocationsStaff } from '../../../redux/actions/selectedStaffSlots'
import {
  fetchStaffPool,
  clearAllSelectedStaff,
  getStaffAssignmentMetadata,
} from '../../../redux/actions/staffPool'
import { fetchUnits } from '../../../redux/actions/units'
import {
  userSettings,
  getAllUsers,
  authenticateUser,
  managePageAuthenticateUser,
  setGlobalUserSettings,
} from '../../../redux/actions/user'

import siteDropdownSelector from '../../../redux/selectors/siteDropdownSelector'

import { UnpublishedChanges } from '../../reusableComponents/unpublishedChanges/UnpublishedChanges'
import HelpModal from '../../reusableComponents/helpModal/HelpModal'
import Version from '../../../../package.json'

/**
 * Styling Imports
 * */
import {
  WrapperForDisabledClick,
  StyledHeader,
  Help,
  StyledNavLink,
  DropdownContainer,
} from './header.styles'

import svg from '../../reusableComponents/CareTeamLogo.svg'
import { NeuTab } from '@neutron/react'

const Header = ({
  authToken,
  sites,
  formattedSites,
  globalUserSettings,
  location,
  navLinks,
  selectedSite,
  selectedStaffSlots,
  selectedUnitIds,
  stagedStaffSlots,
  ui,
  units,
  user,
  dispatch,
}) => {
  const [show, setShow] = useState(false)
  const [sortedSites, setSortedSites] = useState()

  const getVersion = Version.version

  const dropdownSites = sortedSites
    ? sortedSites.map((site) => ({
        facilitycoid: site.facilityCoid,
        facilityid: site.facilityId,
        fhirresourceurl: site.fhirResourceUrl,
        id: site.id,
        key: site.key,
        locationtype: site.locationType,
        text: site.text,
        value: site.value,
      }))
    : []

  const modalRef = useRef(null)

  useEffect(() => {
    const selectedSiteId = selectedSite
      ? selectedSite.siteId
      : formattedSites[0].value
    dispatch(
      userSettings({
        authToken,
        siteId: selectedSiteId,
      }),
    )
    dispatch(fetchUserGroups(authToken, selectedSiteId))

    const siteDropdownSort = () => {
      return formattedSites.sort((a, b) => {
        const normalizeText = (text) => {
          return text
            .toLowerCase()
            .replace(/[^a-z0-9 ]/gi, '')
            .replace(/\s+/g, '')
            .trim()
        }
        const textA = normalizeText(a.text)
        const textB = normalizeText(b.text)
        return textA.localeCompare(textB, 'en', { numeric: true })
      })
    }

    const siteDropdownOptions = siteDropdownSort(formattedSites)

    setSortedSites(siteDropdownOptions)

    dispatch(
      getClinicalRoles({
        authorization: authToken,
        siteId: selectedSiteId,
      }),
    )

    dispatch(
      getStaffAssignmentMetadata({
        authorization: authToken,
        siteId: selectedSiteId,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    const isEmpty = !Object.keys(stagedStaffSlots).length
    if (!isEmpty && !ui.disabledDropdowns) {
      dispatch({ type: 'ui/SET_DISABLED_DROPDOWNS', payload: true })
    } else if (isEmpty && ui.disabledDropdowns) {
      dispatch({ type: 'ui/SET_DISABLED_DROPDOWNS', payload: false })
    }
  }, [dispatch, stagedStaffSlots, ui.disabledDropdowns])

  const grabNewLocations = () => {
    let unitIds
    if (
      (user.userSettings.preferredUnitIds &&
        user.userSettings.preferredUnitIds.includes(
          '00000000-0000-0000-0000-000000000000',
        )) ||
      (user.userSettings.preferredUnitIds &&
        user.userSettings.preferredUnitIds.includes('all'))
    ) {
      unitIds = units.units.map((unit) => unit.id)
      dispatch(fetchAllAssignments(authToken, sites.selectedSiteId, ui))
    } else {
      unitIds = user.userSettings.preferredUnitIds
      dispatch(
        fetchLocationsWithMultipleUnits(
          authToken,
          sites.selectedSiteId,
          unitIds,
          ui,
        ),
      )
    }
  }

  const showUnpublishedModal = () => {
    if (ui.disabledDropdowns === true) {
      dispatch({
        type: 'ui/SET_SHOW_UNPUBLISHED_MODAL',
        payload: true,
      })
    }
  }

  const hideUnpublishedModal = () => {
    dispatch({
      type: 'ui/SET_SHOW_UNPUBLISHED_MODAL',
      payload: false,
    })
    dispatch({ type: 'ui/SET_DISABLED_DROPDOWNS', payload: false })
  }

  const showModal = () => {
    hideUnpublishedModal()
    setShow(true)
  }
  const hideModal = () => {
    setShow(false)
  }

  const handleClickOutside = (event) => {
    if (
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      hideModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (
      selectedSite &&
      selectedSite?.integrationProperties?.LockedRoles &&
      selectedSite?.integrationProperties !== null
    ) {
      dispatch(fetchLockedRoles(authToken, selectedSite.siteId))
      dispatch(fetchLockedRoleConfig(authToken, selectedSite.siteId))
    }
  }, [selectedSite])

  const handleSelect = useCallback(
    ({ option }) => {
      const selSiteId = option.value

      const newUserSettingsObject = {
        ...globalUserSettings,
        preferredSiteId: selSiteId,
      }
      const selectedSite = sites.sites.find((site) => site.siteId === selSiteId)
      if (
        selectedSite &&
        selectedSite?.integrationProperties?.LockedRoles &&
        selectedSite?.integrationProperties !== null
      ) {
        dispatch(fetchLockedRoles(authToken, selSiteId))
        dispatch(fetchLockedRoleConfig(authToken, selSiteId))
      } else {
        dispatch(clearLockedRoles())
      }

      dispatch(
        setGlobalUserSettings({
          authToken,
          globalSettings: newUserSettingsObject,
        }),
      )

      dispatch({ type: 'user/ALL_UNITS_SELECTED', payload: false })

      dispatch(selectSite(selSiteId))
      //clear report and manage page data and selected staff
      dispatch(clearReportData())
      dispatch({ type: 'ui/CLEAR_AUDIT_TYPE_AND_TIME' })
      dispatch({ type: 'ui/CLEAR_MISSING_ASSIGNMENT_DATA' })
      dispatch({ type: 'ui/CLEAR_SNAPSHOT_DATE_AND_TIME' })
      dispatch({ type: 'assignments/CLEAR_PREFERRED_STAFF_POOL' })
      dispatch(clearAllSelectedStaff())
      dispatch(clearAllSelectedLocationsStaff())

      if (!window.location.pathname.replace('/', '').includes('manage')) {
        dispatch(fetchUnits(authToken, selSiteId))
        dispatch(
          //Pass null values to get a list of all users in db
          getAllUsers({
            facilityId: null,
            authToken,
            searchText: ui.searchText,
            divisionId: null,
            clinicalRole: null,
            skip: 0,
            take: ui.take,
            sortBy: null,
            sortDirection: null,
          }),
        )
        dispatch(
          managePageAuthenticateUser({
            user34: user.user.hca34,
            authToken: user.token,
            siteId: selSiteId,
          }),
        )
        dispatch(
          authenticateUser({
            user34: user.user.hca34,
            authToken: user.token,
            preferredSiteId: selSiteId,
          }),
        )
        // load all the sites data
        dispatch(
          getClinicalRoles({
            authorization: authToken,
            siteId: selSiteId,
          }),
        )
        dispatch(fetchUserGroups(authToken, selSiteId))
        dispatch(fetchStaffPool(authToken, selSiteId))
      }

      dispatch(
        userSettings({
          authToken,
          siteId: selSiteId,
        }),
      )
    },
    [
      authToken,
      globalUserSettings,
      ui.searchText,
      ui.take,
      user.token,
      user.user,
      dispatch,
    ],
  )

  /**
   * Tracking for initial page load
   */

  const { logTrackingEvent } = useAnalyticsApi()
  const ctaLastPath = localStorage.getItem('ctaLastPath')
  useEffect(() => {
    const tabMappings = {
      locationassignments: 'Location Tab',
      patient: 'Patient Tab',
    }

    const manageMappings = {
      'manage/users': Events.Manage.Screen[0].Name,
      'manage/locations': Events.Manage.Screen[1].Name,
      'manage/patients': Events.Manage.Screen[2].Name,
    }

    const reportsMappings = {
      'reports/snapshot': Events.Reports.Screen[0].Name,
      'reports/missingassignments': Events.Reports.Screen[2].Name,
      'reports/assignmentaudit': Events.Reports.Screen[1].Name,
    }

    const tab = Object.keys(tabMappings).find((key) =>
      ctaLastPath?.includes(key),
    )

    if (tab) {
      logTrackingEvent([
        {
          module: tabMappings[tab],
          screen: tabMappings[tab],
          eventName: tabMappings[tab],
          eventType: 'start',
          org: {
            orgId: selectedSite.facilityCoid,
            orgLevel: 'Facility',
          },
        },
      ])
    }

    const manageTab = Object.keys(manageMappings).find((key) =>
      ctaLastPath?.includes(key),
    )

    const reportsTab = Object.keys(reportsMappings).find((key) =>
      ctaLastPath?.includes(key),
    )

    if (manageTab) {
      logTrackingEvent([
        {
          module: Events.Manage.Module,
          screen: manageMappings[manageTab],
          eventName: manageMappings[manageTab],
          eventType: 'start',
          org: {
            orgId: selectedSite.facilityCoid,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (reportsTab) {
      logTrackingEvent([
        {
          module: Events.Reports.Module,
          screen: reportsMappings[reportsTab],
          eventName: reportsMappings[reportsTab],
          eventType: 'start',
          org: {
            orgId: selectedSite.facilityCoid,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }, [])

  /**
   * Tracking for nav tab clicks
   */

  /**
   * Renders the navigation links based on the user's permissions and current tab.
   *
   * @param {Array} navLinks - The array of navigation links.
   * @param {string} currentTab - The current active tab.
   * @returns {Array} - The filtered and mapped array of allowed navigation links.
   */
  const history = useHistory()

  const trackingTabClicks = () => {
    history.listen((location) => {
      let ctaLastPath = location.pathname
      if (ctaLastPath.includes('locationassignments')) {
        logTrackingEvent([
          {
            module: Events.Locations.Module,
            screen: Events.Locations.Screen[0].Name,
            eventName: Events.Locations.Screen[0].Events.Current_TabClick,
            eventType: 'action',
            org: {
              orgId: selectedSite.facilityCoid,
              orgLevel: 'Facility',
            },
          },
        ])
      } else if (ctaLastPath.includes('patientassignments')) {
        logTrackingEvent([
          {
            module: Events.Patients.Module,
            screen: Events.Patients.Screen.Name,
            eventName: Events.Patients.Screen.Events.Patient_TabClick,
            eventType: 'action',
            org: {
              orgId: selectedSite.facilityCoid,
              orgLevel: 'Facility',
            },
          },
        ])
      } else if (ctaLastPath.includes('reports/snapshot')) {
        logTrackingEvent([
          {
            module: Events.Reports.Module,
            screen: Events.Reports.Screen[0].Name,
            eventName: Events.Reports.Screen[0].Events.Snapshot_ReportClick,
            eventType: 'action',
            org: {
              orgId: selectedSite.facilityCoid,
              orgLevel: 'Facility',
            },
          },
        ])
      } else if (ctaLastPath.includes('manage/users')) {
        logTrackingEvent([
          {
            module: Events.Manage.Module,
            screen: Events.Manage.Screen[0].Name,
            eventName: Events.Manage.Screen[0].Events.Users_TabClick,
            eventType: 'action',
            org: {
              orgId: selectedSite.facilityCoid,
              orgLevel: 'Facility',
            },
          },
        ])
      }
    })
  }
  /**
   * Renders the navigation links based on the user's permissions and current tab.
   *
   * @param {Array} navLinks - The array of navigation links.
   * @param {string} currentTab - The current active tab.
   * @returns {Array} - The filtered and mapped array of allowed navigation links.
   */
  const renderLinks = (navLinks, currentTab) => {
    const allowedLinks = []
    // TODO: investigate if needed
    if (user.user) {
      navLinks.forEach((link) => {
        if (
          link.allowedRoles.some((link) => user.userPermissions.includes(link))
        ) {
          allowedLinks.push(Object.assign(link, { navBarItem: true }))
        }
      })
    }
    return allowedLinks
      .filter((link) => {
        return link.navBarItem
      })
      .map((item, index) => {
        return (
          <NeuTab
            className="neu-text-body"
            unit="group"
            kind="blue"
            color="orange"
            size="small"
            key={item.navItemText}
            style={{ marginTop: '5px' }}
            onClick={(e) => {
              if (item.navItemText !== 'Manage') {
                // Clear searchText and users list when navigating away from Manage tab
                dispatch({ type: 'user/CLEAR_USERS' })
                dispatch({ type: 'user/CLEAR_SEARCH' })
              }
              if (Object.keys(stagedStaffSlots).length > 0) {
                e.preventDefault()
                showUnpublishedModal()
              }
              if (item.navItemText !== 'Location' || 'Patient') {
                // Clear searchText and users list when navigating away from Locations or Patients tab
                dispatch({ type: 'locations/CLEAR_SEARCH' })
              }
            }}
          >
            <WrapperForDisabledClick isDisabled={ui.disabledDropdowns}>
              <StyledNavLink
                to={item.link}
                style={{ textDecoration: 'none' }}
                onClick={(Events) => {
                  trackingTabClicks()
                  grabNewLocations()
                  if (Object.keys(selectedStaffSlots).length > 0) {
                    dispatch(clearAllSelectedLocationsStaff())
                  }
                  dispatch({
                    type: 'ui/MANAGE_SLIDING_PANE',
                    payload: {
                      open: false,
                      source: '',
                      data: {},
                      userExists: false,
                    },
                  })
                  dispatch(clearReportData())
                  dispatch({ type: 'ui/CLEAR_AUDIT_TYPE_AND_TIME' })
                  if (item.navItemText === 'Reports') {
                    dispatch({
                      type: 'ui/REPORTS_SLIDING_PANE',
                      payload: {
                        open: true,
                        source: 'reports',
                        data: {},
                      },
                    })
                  }
                  dispatch(
                    authenticateUser({
                      user34: user.user.hca34,
                      authToken: user.token,
                      preferredSiteId: selectedSite.facilityCoid,
                    }),
                  )
                }}
                activeClassName="neu-tab--is-active"
              >
                <NeuTab
                  unit="tab"
                  style={{ padding: ' 16px 8px 16px 8px' }}
                  isDisabled={ui.disabledDropdowns}
                  navy
                  active={
                    item.subLinks &&
                    item.subLinks.find((subLink) => subLink === currentTab)
                      ? true
                      : currentTab === item.link
                  }
                >
                  {item.navItemText}
                </NeuTab>
              </StyledNavLink>
            </WrapperForDisabledClick>
          </NeuTab>
        )
      })
  }
  const eventProperties = {
    user: user.user34,
    timeOfClick: moment().format('MM/DD/YYYY HH:mm:ss'),
  }

  const showHideClassName = show ? 'modal display-block' : 'modal display-none'

  const globalSettingsConfig = {
    siteId: sites.selectedSiteId,
    authToken: authToken,
    globalSettings: user.globalUserSettings,
  }
  const userSettingsConfig = {
    siteId: sites.selectedSiteId,
    authToken: authToken,
    userSettings: user.userSettings,
  }

  const currentTab = location.pathname

  const isNotManage = !window.location.pathname
    .replace('/', '')
    .includes('manage')

  return (
    <>
      <StyledHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className="projectTitle" style={{ minWidth: '149px' }}>
            <img
              alt="Care Team Logo"
              style={{
                height: '19px',
                display: 'inline',
                marginRight: '5px',
                marginTop: '3px',
                width: '149px',
                marginBottom: '5px',
              }}
              src={svg}
            />
            <div style={{ paddingLeft: '2px',marginTop: '6px', fontSize: '12px' }}>{getVersion}</div>
          </div>
          {renderLinks(navLinks, currentTab)}
        </div>
        <div onClick={handleClickOutside}>
          <HelpModal
            showHideClassName={showHideClassName}
            globalSettingsConfig={globalSettingsConfig}
            userSettingsConfig={userSettingsConfig}
            dispatch={dispatch}
            handleCloseButton={hideModal}
            ref={modalRef}
          />
        </div>
        <UnpublishedChanges
          showUnpublishedState={ui.disabledDropdowns && ui.showUnpublishedModal}
          hideModal={hideUnpublishedModal}
          stagedStaffSlots={stagedStaffSlots}
          page={window.location.pathname
            .substr(1)
            .split('assignments')[0]
            .concat('s')}
          siteId={sites.selectedSiteId}
          selectedUnitIds={selectedUnitIds}
          authToken={authToken}
          ui={ui}
          eventProperties={eventProperties}
          dispatch={dispatch}
          allUnitsSelected={user.allUnitsSelected}
        />
        <div>
          <DropdownContainer onClick={() => showUnpublishedModal()}>
            {isNotManage && (
              <Dropdown
                className="siteDropdown"
                style={{
                  color: '#ffffff',
                  backgroundColor: '#03173e',
                  borderRadius: '5px',
                  paddingBottom: '10px',
                  fontFamily: 'HCA-Mark',
                }}
                ui="true"
                value={selectedSite && selectedSite.siteId}
                placeholder={selectedSite && selectedSite.displayName}
                search
                floating
                selection
                selectOnNavigation={false}
                options={dropdownSites || []}
                onChange={(e, d) => {
                  dispatch({
                    type: 'locations/CLEAR_SEARCH',
                  })
                  handleSelect({ option: d })

                  dispatch(
                    getStaffAssignmentMetadata({
                      authorization: authToken,
                      siteId: d.value,
                    }),
                  )
                }}
                disabled={ui.disabledDropdowns}
              />
            )}

            <Help
              onClick={(e) => {
                e.stopPropagation()

                showModal()
              }}
            >
              <i className="material-icons">help</i>
              <div style={{ paddingTop: '2px' }}>Help</div>
            </Help>
          </DropdownContainer>
        </div>
      </StyledHeader>
    </>
  )
}

export default withRouter(
  connect((state, props) => siteDropdownSelector(state, props))(Header),
)
